import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { IDataTableColumn } from 'react-data-table-component';
import { SalesEstimateDocument } from '../model';

import axios from '../../utils/axios.utils';

import DataTable from '../../dataTable/DataTable/DataTable';

interface JobFolderProps {
  documents: SalesEstimateDocument[];
}

const JobFolder: React.FC<JobFolderProps> = (props) => {
  let { documents } = props;

  const downloadSingleFile = async (row) => {
    try {
      let response: any = await axios.get(`/sales_estimate_documents/${row.id}/download`, {
        responseType: 'blob', // Ensure axios treats the response as a blob
      });

      if (response.status === 200) {
        let blob = new Blob([response.data], { type: response.data.type });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = row?.description || 'download.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(`Failed to fetch ${row?.description}`);
      }
    } catch (error) {
      console.error(`Failed to fetch ${row?.description}`, error);
    }
  };

  const downloadZipFolder = async () => {
    const zipFolder = new JSZip();

    try {
      for (let document of documents) {
        const response = await axios.get(`/sales_estimate_documents/${document.id}/download`, {
          params: { url: document.url },
          responseType: 'blob',
        });

        if (response.status !== 200) {
          throw new Error(`Failed to fetch ${document.description}`);
        }

        // Add the blob to the zip folder
        const blob = response.data;
        zipFolder.file(`${document.description}.pdf`, blob);
      }

      // Generate the zip file after all files have been added
      const zipBlob = await zipFolder.generateAsync({ type: 'blob' });

      saveAs(zipBlob, 'job_folder.zip');
    } catch (error) {
      console.error('Error downloading zip folder:', error);
    }
  };

  let jobFolderColumns: IDataTableColumn<any>[] = [
    {
      name: 'Description',
      selector: 'description',
    },
    {
      name: 'Actions',
      selector: 'url',
      cell: (row) => <Button onClick={() => downloadSingleFile(row)}>Download</Button>,
    },
  ];

  return (
    <Accordion>
      <AccordionSummary>
        <Typography> Job Folder</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={downloadZipFolder}>
              Download Zip Folder
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              noheader
              columns={jobFolderColumns}
              data={documents}
              striped
              dense
              highlightOnHover
              pointerOnHover
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default JobFolder;
