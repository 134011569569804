import React, { useState, Dispatch, SetStateAction } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import { SalesEstimateKeyDetails } from '../model';

import SelectInput from '../../form/components/SelectInput';
import TextInput from '../../form/components/TextInput';

interface KeyDetailsEditProps {
  keyDetails: SalesEstimateKeyDetails;
  formValues: SalesEstimateKeyDetails;
  setFormValues: Dispatch<SetStateAction<SalesEstimateKeyDetails>>;
}

const styles = {
  keyDetailsGrid: {
    marginBottom: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  infoLabel: {
    fontWeight: 550,
    fontSize: 16,
  },
};

const urgencyChoices = [
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
  { value: 'ASAP', label: 'ASAP' },
];

const turnoverMeetingChoices = [
  { value: 'Phone', label: 'Phone' },
  { value: 'In Person', label: 'In Person' },
  { value: 'Video', label: 'Video' },
];

const closeoutTypeChoices = [
  { value: 'Email', label: 'Email' },
  { value: 'In Person', label: 'In Person' },
  { value: 'Phone', label: 'Phone' },
  { value: 'None', label: 'None' },
];

const startupTypeChoices = [
  { value: 'Projects', label: 'Projects' },
  { value: 'Service', label: 'Service' },
  { value: 'None', label: 'None' },
];

const KeyDetailsEdit: React.FC<KeyDetailsEditProps> = (props) => {
  let { formValues, setFormValues } = props;

  return (
    <Grid container>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Folder Clean
        </Typography>
        <Switch
          checked={formValues?.folder_clean}
          onChange={(event) => setFormValues({ ...formValues, folder_clean: event.target.checked })}
        />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Tax Exempt
        </Typography>
        <Switch
          checked={formValues?.tax_exempt}
          onChange={(event) => setFormValues({ ...formValues, tax_exempt: event.target.checked })}
        />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Night Work
        </Typography>
        <Switch
          checked={formValues?.night_work}
          onChange={(event) => setFormValues({ ...formValues, night_work: event.target.checked })}
        />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Weekend Work
        </Typography>
        <Switch
          checked={formValues?.wknd_work}
          onChange={(event) => setFormValues({ ...formValues, wknd_work: event.target.checked })}
        />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Urgency
        </Typography>
        <SelectInput
          value={formValues?.urgency}
          choices={urgencyChoices}
          valueChanged={(value) => setFormValues({ ...formValues, urgency: value })}
          label=""
        />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Permit Required
        </Typography>
        <Switch
          checked={formValues?.permit_required}
          onChange={(event) => setFormValues({ ...formValues, permit_required: event.target.checked })}
        />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Turnover Meeting
        </Typography>
        <SelectInput
          value={formValues?.turnover_mtg}
          choices={turnoverMeetingChoices}
          valueChanged={(value) => setFormValues({ ...formValues, turnover_mtg: value })}
          label=""
        />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Closeout Type
        </Typography>
        <SelectInput
          value={formValues?.closeout_type}
          choices={closeoutTypeChoices}
          valueChanged={(value) => setFormValues({ ...formValues, closeout_type: value })}
          label=""
        />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Startup Type
        </Typography>
        <SelectInput
          value={formValues?.startup_type}
          choices={startupTypeChoices}
          valueChanged={(value) => setFormValues({ ...formValues, startup_type: value })}
          label=""
        />{' '}
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <Typography variant="subtitle1" component="h6" style={styles.infoLabel}>
          Lead Tech
        </Typography>
        <TextInput
          value={formValues?.lead_tech}
          valueChanged={(value) => setFormValues({ ...formValues, lead_tech: value })}
          label=""
        />
      </Grid>
    </Grid>
  );
};

export default KeyDetailsEdit;
