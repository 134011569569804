import React, { useContext } from 'react';
import { Modal, Card, Typography, Button, Grid } from '@mui/material';
import axios from '../../utils/axios.utils';
import { EstimateDetailsContext } from '../contexts/EstimateDetailsContext';
import InformationField from '../../form/components/InformationField';

type RecentEstimatesDeleteModalProps = {
  afterDelete?: () => void;
};

const RecentEstimatesDeleteModal: React.FC<RecentEstimatesDeleteModalProps> = (props) => {
  const { afterDelete } = props;

  const { recentEstimatesDeleteModalOpen, setRecentEstimatesDeleteModalOpen, focusEstimate } =
    useContext(EstimateDetailsContext);

  const handleDelete = async () => {
    await axios.delete(`/sales_estimates/${focusEstimate.id}`);
    setRecentEstimatesDeleteModalOpen(false);
    if (afterDelete) afterDelete();
  };

  return (
    <Modal
      open={recentEstimatesDeleteModalOpen}
      onClose={() => setRecentEstimatesDeleteModalOpen(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card style={{ padding: 20, textAlign: 'center' }}>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Are you sure you want to delete this sales estimate?
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <InformationField label="Name" value={focusEstimate?.name} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Customer" value={focusEstimate?.customer_name} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Site" value={focusEstimate?.site_name} />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
          <Button variant="contained" color="error" onClick={handleDelete} style={{ marginRight: 10 }}>
            Delete
          </Button>
          <Button variant="contained" color="secondary" onClick={() => setRecentEstimatesDeleteModalOpen(false)}>
            Cancel
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default RecentEstimatesDeleteModal;
