import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import axios from '../../utils/axios.utils';

import SelectInput from '../../form/components/SelectInput';
import TextInput from '../../form/components/TextInput';

import { Rebate, RebateRecord } from '../model';
import { Typography } from '@mui/material';

let mockChoices = [
  { label: 'RTU-01', value: '1' },
  { label: 'RTU-02', value: '2' },
  { label: 'RTU-03', value: '3' },
];

interface AddRebateModalProps {
  selectedRebate: Rebate | null;
  setSelectedRebate: Dispatch<SetStateAction<Rebate | null>>;
}

const AddRebateModal: React.FC<AddRebateModalProps> = (props) => {
  let { selectedRebate, setSelectedRebate } = props;

  let [formValues, setFormValues] = useState<RebateRecord>();
  let [disableApplyButton, setDisableApplyButton] = useState<boolean>(true);

  const handleApply = async () => {
    let response: any = await axios.post('/rebate_records', {
      rebate_id: selectedRebate?.id,
      sales_estimate_asset_id: formValues?.sales_estimate_asset_id,
      rebate_record: formValues,
    });

    setFormValues(response.data);
    setSelectedRebate(null);
  };

  useEffect(() => {
    if (formValues?.sales_estimate_asset_id && formValues?.job_units) {
      setDisableApplyButton(false);
    } else {
      setDisableApplyButton(true);
    }
  });

  return (
    <Modal
      open={selectedRebate !== null}
      onClose={() => setSelectedRebate(null)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card style={{ padding: 20 }}>
        <Grid container style={{ width: '450px' }} spacing={2}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6">Apply Rebate</Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography gutterBottom variant="h5" component="div">
              {selectedRebate?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2">Technology: {selectedRebate?.technology}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Specifx L2 Description: {selectedRebate?.l2_description}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Source: {selectedRebate?.source}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Rebate Rate:
                  {selectedRebate?.min_rebate_rate === selectedRebate?.max_rebate_rate
                    ? selectedRebate?.min_rebate_rate
                    : `${selectedRebate?.min_rebate_rate}-${selectedRebate?.max_rebate_rate}/${selectedRebate?.rebate_uom}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              label="Asset Number"
              value={formValues?.sales_estimate_asset_id}
              valueChanged={(value) => setFormValues({ ...formValues, sales_estimate_asset_id: value })}
              choices={mockChoices}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Job Units"
              value={formValues?.job_units}
              shrinkLabel
              valueChanged={(value) => setFormValues({ ...formValues, job_units: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => setSelectedRebate(null)}
              style={{ backgroundColor: 'lightgray', color: 'black' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApply}
              style={{ marginLeft: 15 }}
              disabled={disableApplyButton}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};

export default AddRebateModal;
