import React, { useContext } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

import { Rebate } from '../model';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

import axios from '../../utils/axios.utils';

interface RebateOptionCardProps {
  rebate: Rebate;
}

const RebateOptionCard: React.FC<RebateOptionCardProps> = (props) => {
  let { rebate } = props;

  let { salesEstimate, setSalesEstimate } = useContext(EstimateDetailsContext);

  let associateRebateWithEstimate = async () => {
    let response: any = await axios.post(`rebates/${rebate?.id}/join`, {
      sales_estimate_id: salesEstimate.id,
    });

    setSalesEstimate(response.data.result);
  };

  let backgroundColor = 'aliceblue';
  let textColor = 'DarkSlateGrey';

  if (salesEstimate.rebate_ids?.includes(rebate.id)) {
    backgroundColor = '#1976d2';
    textColor = '#fff';
  }

  return (
    <Card
      variant={'outlined'}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: 0,
        padding: '10px',
      }}
    >
      <CardActionArea onClick={associateRebateWithEstimate}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography gutterBottom variant="h5" component="div">
                {rebate.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: textColor }}>
                Technology: {rebate.technology}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: textColor }}>
                Specifx L2 Description: {rebate.l2_description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: textColor }}>
                Source: {rebate.source}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: textColor }}>
                Rebate Rate:
                {rebate.min_rebate_rate === rebate.max_rebate_rate
                  ? rebate.min_rebate_rate
                  : `${rebate.min_rebate_rate}-${rebate.max_rebate_rate}/${rebate.rebate_uom}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default RebateOptionCard;
