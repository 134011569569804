import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { EquipmentCostsContext } from '../contexts/EquipmentCostsContext';
import DataTable from '../../dataTable/DataTable/DataTable';
import EquipmentCostFilterBar from '../components/EquipmentCostFilterBar';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Manufacturer',
    selector: 'manufacturer',
    sortable: true,
  },
  {
    name: 'Asset Type',
    selector: 'asset_type',
    sortable: true,
  },
  {
    name: 'Model',
    selector: 'model_number',
    sortable: true,
  },
  {
    name: 'Asset Size',
    selector: 'label',
    sortable: true,
  },
  {
    name: 'Default Cost',
    selector: 'formatted_default_cost',
    sortable: true,
  },
  {
    name: 'Actions',
    sortable: true,
    selector: 'manual_link',
    cell: (row) => (
      <Button variant="contained" color="primary" onClick={() => window.open(row.manual_link)}>
        View Manual
      </Button>
    ),
  },
];

const CostProfilesList: React.FunctionComponent = () => {
  const history = useHistory();

  const [costProfileFetched, setCostProfileFetched] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState({});
  const { fetchEquipmentCosts, equipmentCosts, equipmentCostsLoading } = React.useContext(EquipmentCostsContext);

  React.useEffect(() => {
    if (!costProfileFetched) {
      fetchEquipmentCosts();
      setCostProfileFetched(true);
    }
  }, []);

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ marginTop: 5 }}>
              Equipment Costs
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push('/admin/equipment_costs/new')}>
              New Equipment Cost +
            </Button>
          </Grid>
        </Grid>
        <br />
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <EquipmentCostFilterBar filterValues={filterValues} setFilterValues={setFilterValues} />
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title="Equipment Costs"
              columns={columns}
              data={equipmentCosts}
              onRowClicked={(row) => history.push(`/admin/equipment_costs/${row.id}/edit`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default CostProfilesList;
