import React from 'react';
import { FormControlLabel, Checkbox, Button, Grid, GridSize, Typography } from '@mui/material';
import { SelectOption } from '../../form/model';
import FilterSelectInput from '../../form/components/FilterSelectInput';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface EquipmentCostFilterBarProps {
  filterValues?: any;
  setFilterValues?: any;
}

const EquipmentCostFilterBar: React.FC<EquipmentCostFilterBarProps> = (props) => {
  const { filterValues, setFilterValues } = props;

  const handleApplyFilters = async () => {};

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12} md={2}>
        <FilterSelectInput
          value={filterValues?.name}
          choices={[
            { label: 'Equipment', value: 'equipment' },
            { label: 'Installation', value: 'installation' },
          ]}
          onChange={(value) => setFilterValues({ ...filterValues, name: value })}
          label="Category"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FilterSelectInput
          value={filterValues?.online_status}
          onChange={(value) => setFilterValues({ ...filterValues, online_status: value })}
          label="Manufactuer"
          defaultChoice={{ label: 'All', value: 'status__all' }}
          choices={[
            { label: 'Trane', value: 'Offline' },
            { label: 'Carrier', value: 'Online' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff', marginTop: 19 }}
          disableElevation
          onClick={() => {
            handleApplyFilters();
          }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

export default EquipmentCostFilterBar;
