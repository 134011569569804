import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SelectInput from '../../form/components/SelectInput';
import TextInput from '../../form/components/TextInput';
import axios from '../../utils/axios.utils';

import { InstallationCostRecord } from '../../estimateDetails/model';

interface InstallationCostModalProps {
  editModalOpen: boolean;
  setEditModalOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  unitLabel: string;
  salesEstimate: any;
  descriptionOptions?: any[];
  recordToEdit?: InstallationCostRecord;
}

const InstallationCostModal: React.FC<InstallationCostModalProps> = (props) => {
  const {
    editModalOpen,
    setEditModalOpen,
    title,
    unitLabel,
    salesEstimate,
    descriptionOptions = [],
    recordToEdit,
  } = props;

  let [formValues, setFormValues] = useState<InstallationCostRecord>({});
  let [materialCategory, setMaterialCategory] = useState('');
  let [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    let total = parseFloat(formValues?.cost_per_unit) * parseFloat(formValues?.job_units) || 0;
    setTotalCost(total);
  }, [formValues]);

  const handleSubmit = async () => {
    const response = await axios.put(`/installation_cost_records/${recordToEdit.id}`, {
      installation_cost_record: formValues,
    });

    window.location.reload();
  };

  useEffect(() => {
    setFormValues(recordToEdit);
  }, [recordToEdit]);

  return (
    <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
      <DialogTitle>Edit {title}</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <SelectInput
          label="Asset Number"
          value={formValues?.sales_estimate_asset_id}
          valueChanged={(value) => setFormValues({ ...formValues, sales_estimate_asset_id: value })}
          choices={salesEstimate.sales_estimate_assets?.flatMap((asset) => ({
            label: asset.asset_number,
            value: asset.id,
          }))}
        />
        <br />
        <SelectInput
          label="Description"
          value={formValues?.installation_cost_id}
          valueChanged={(value) => {
            setFormValues({ ...formValues, installation_cost_id: value });
            setMaterialCategory(descriptionOptions.filter((option) => option.value === value)[0]?.label);
          }}
          choices={descriptionOptions}
        />
        <br />
        {materialCategory === 'Other' && (
          <>
            <TextInput
              label="Other Description"
              value={formValues?.description_override}
              shrinkLabel
              valueChanged={(value) => setFormValues({ ...formValues, description_override: value })}
            />
            <br />
          </>
        )}
        <TextInput
          label={`Cost per ${unitLabel}`}
          value={formValues?.cost_per_unit}
          shrinkLabel
          currency
          valueChanged={(value) => setFormValues({ ...formValues, cost_per_unit: value })}
        />
        <br />
        <TextInput
          label={`${unitLabel}s`}
          value={formValues?.job_units}
          shrinkLabel
          valueChanged={(value) => setFormValues({ ...formValues, job_units: value })}
        />
        <br />
        <Typography style={{ marginTop: 10 }}>Total Cost: ${totalCost?.toFixed(2) || ''}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditModalOpen(false)}>Cancel</Button>
        <Button type="submit" onClick={handleSubmit}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstallationCostModal;
