import React from 'react';
import axios from '../../utils/axios.utils';
import { EquipmentCostFormValues, CostProfile } from '../model';
import { SelectOption } from '../../form/model';

interface EquipmentCostFormChoicesType {
  asset_types: SelectOption[];
  manufacturers: SelectOption[];
}

interface EquipmentCostsContextInterface {
  fetchEquipmentCosts?: () => void;
  equipmentCosts?: CostProfile[];
  equipmentCostsLoading?: boolean;

  fetchEquipmentCost?: (costProfile_id: string) => void;
  costProfile?: CostProfile;
  costProfileLoading?: boolean;

  fetchChoices?: () => void;
  equipmentCostFormChoices?: EquipmentCostFormChoicesType;

  formChoices?: SelectOption[];

  createCostProfile?: (formValues: EquipmentCostFormValues, onSuccess: () => void) => void;
  updateCostProfile?: (costProfileId: string, formValues: EquipmentCostFormValues, onSuccess: () => void) => void;
  costProfileSubmitting?: boolean;
}

interface EquipmentCostsContextProviderProps {
  children: React.ReactNode;
}

const EquipmentCostsContext = React.createContext<EquipmentCostsContextInterface>({});

const EquipmentCostsContextConsumer = EquipmentCostsContext.Consumer;
const EquipmentCostsContextProvider: React.FC<EquipmentCostsContextProviderProps> = ({ children }) => {
  const [equipmentCosts, setEquipmentCosts] = React.useState<CostProfile[]>([]);
  const [equipmentCostsLoading, setEquipmentCostsLoading] = React.useState<boolean>(true);

  const [costProfile, setEquipmentCost] = React.useState<CostProfile>({});
  const [costProfileLoading, setEquipmentCostLoading] = React.useState<boolean>(true);

  const [equipmentCostFormChoices, setEquipmentCostFormChoices] = React.useState<EquipmentCostFormChoicesType>();

  const [costProfileSubmitting, setEquipmentCostSubmitting] = React.useState<boolean>(false);

  const fetchEquipmentCosts = async () => {
    const response = await axios.get<string, any>('/equipment_costs');

    setEquipmentCosts(response?.data?.result);
    setEquipmentCostsLoading(false);
  };

  const fetchEquipmentCost = async (costProfileId: string) => {
    const response = await axios.get<string, any>(`/equipment_costs/${costProfileId}`);

    setEquipmentCost(response?.data?.result);
    setEquipmentCostLoading(false);
  };

  const fetchChoices = async () => {
    const response = await axios.get<string, any>('/equipment_costs/new');
    setEquipmentCostFormChoices(response?.data);
  };

  const createCostProfile = async (formValues: EquipmentCostFormValues, onSucess: () => void) => {
    setEquipmentCostSubmitting(true);

    await axios
      .post<EquipmentCostFormValues, any>('/equipment_costs/', { ...formValues })
      .then((response) => {
        onSucess();
      })
      .catch((error) => {
        setEquipmentCostSubmitting(false);
      });
  };

  const updateCostProfile = async (
    costProfileId: string,
    formValues: EquipmentCostFormValues,
    onSucess: () => void,
  ) => {
    setEquipmentCostSubmitting(true);

    await axios
      .put<EquipmentCostFormValues, any>(`/equipment_costs/${costProfileId}`, { ...formValues })
      .then((response) => {
        onSucess();
      })
      .catch((error) => {
        setEquipmentCostSubmitting(false);
      });
  };

  return (
    <EquipmentCostsContext.Provider
      value={{
        fetchEquipmentCosts,
        equipmentCosts,
        equipmentCostsLoading,

        fetchEquipmentCost,
        costProfile,
        costProfileLoading,

        fetchChoices,
        equipmentCostFormChoices,

        createCostProfile,
        updateCostProfile,
        costProfileSubmitting,
      }}
    >
      {children}
    </EquipmentCostsContext.Provider>
  );
};

export { EquipmentCostsContextProvider, EquipmentCostsContextConsumer, EquipmentCostsContext };
