import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { User } from '../model';

interface AuthenticationContextInterface {
  user: User;
  setUser?: Dispatch<SetStateAction<User>>;
  login?: (email: string, password: string, onSuccess: () => void) => Promise<void>;
  logout?: () => Promise<void>;
  currentUserHasRole?: (role: string) => boolean;
  loginFormError?: string;
  setLoginError?: Dispatch<SetStateAction<string>>;
}

interface AuthenticationContextProviderProps {
  children: React.ReactNode;
}

const AuthenticationContext = React.createContext<AuthenticationContextInterface>({ user: {} });

const AuthenticationContextConsumer = AuthenticationContext.Consumer;
const AuthenticationContextProvider: React.FC<AuthenticationContextProviderProps> = ({ children }) => {
  const history = useHistory();

  const [user, setUser] = useState<any>();
  const [loginFormError, setLoginError] = useState<string>(undefined);

  const login = async (email: string, password: string, onSuccess: () => void) => {
    console.log('login');
    setUser({ id: '1' });
    localStorage.setItem('token', '1');

    if (onSuccess) {
      onSuccess();
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem('token');
      setUser({});
      window.location.href = '/login';
    } catch {
      setUser({});
    }
  };

  const currentUserHasRole = (role: string) => {
    if (user?.roles?.find((user_role: string) => user_role === role)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        // const result = await axios.get<unknown, Record<string, Record<string, unknown>>>(
        //   'http://localhost:3000/sessions',
        // );

        if (localStorage.getItem('token').length > 0) {
          setUser({ id: localStorage.getItem('token') });
        }
      } catch (error) {
        setUser({});
      }
    }

    if (user === undefined) {
      fetchUser();
    }
  }, [user]);

  return (
    <AuthenticationContext.Provider
      value={{ user, setUser, login, logout, currentUserHasRole, loginFormError, setLoginError }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
export { AuthenticationContextProvider, AuthenticationContextConsumer, AuthenticationContext };
