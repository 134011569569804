import React, { ChangeEvent } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { RebatesContext } from '../contexts/RebatesContext';
import { Rebate, RebateFormValues } from '../../estimateRebates/model';
import techOptions from '../../estimateRebates/constants/techOptions';

interface RebateFormProps {
  rebate?: Rebate;
  onSuccess?: () => void;
}

const RebateForm: React.FunctionComponent<RebateFormProps> = (props) => {
  const { rebate, onSuccess } = props;
  const [formValues, setFormValues] = React.useState<RebateFormValues>();
  const [choicesFetched, setChoicesFetched] = React.useState(false);

  const { createRebate, updateRebate, fetchChoices, formOptions } = React.useContext(RebatesContext);

  React.useEffect(() => {
    setFormValues({
      id: rebate?.id,
      name: rebate?.name,
      technology: rebate?.technology,
      l2_description: rebate?.l2_description,
      max_rebate_rate: rebate?.max_rebate_rate,
      min_rebate_rate: rebate?.min_rebate_rate,
      rebate_uom: rebate?.rebate_uom,
      source: rebate?.source,
      utility_provider_id: rebate?.utility_provider_id,
    });
  }, [rebate]);

  React.useEffect(() => {
    if (!choicesFetched) {
      fetchChoices();
      setChoicesFetched(true);
    }
  }, [fetchChoices, choicesFetched, setChoicesFetched]);

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleCreateRebate = () => {
    createRebate(formValues, onSuccess);
  };

  const handleUpdateRebate = () => {
    updateRebate(rebate.id, formValues, onSuccess);
  };

  return (
    <>
      <br />
      <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextInput
              type="text"
              label="Name"
              shrinkLabel
              value={formValues?.name}
              valueChanged={(value) => setFormValues({ ...formValues, name: value })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SelectInput
              type="text"
              label="Utility Provider"
              choices={formOptions?.utility_provider_options}
              value={formValues?.utility_provider_id}
              valueChanged={(value) => setFormValues({ ...formValues, utility_provider_id: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Technology"
              choices={techOptions}
              value={formValues?.technology}
              valueChanged={(value) => setFormValues({ ...formValues, technology: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Specfx L2 Description"
              shrinkLabel
              value={formValues?.l2_description}
              valueChanged={(value) => setFormValues({ ...formValues, l2_description: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Min Rebate Rate"
              shrinkLabel
              value={formValues?.min_rebate_rate}
              valueChanged={(value) => setFormValues({ ...formValues, min_rebate_rate: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Max Rebate Rate"
              shrinkLabel
              value={formValues?.max_rebate_rate}
              valueChanged={(value) => setFormValues({ ...formValues, max_rebate_rate: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Rebate UOM"
              choices={[
                { label: '$/asset', value: 'asset' },
                { label: '$/kWh', value: 'kWh' },
                { label: '$/job', value: 'job' },
              ]}
              value={formValues?.rebate_uom}
              valueChanged={(value) => setFormValues({ ...formValues, rebate_uom: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Source"
              choices={[
                { label: 'Utility Genius', value: 'utility_genius' },
                { label: 'Internal', value: 'internal' },
              ]}
              value={formValues?.source}
              valueChanged={(value) => setFormValues({ ...formValues, source: value })}
            />
          </Grid>
        </Grid>
      </form>
      {formValues?.id ? (
        <Button variant="contained" color="primary" onClick={handleUpdateRebate}>
          Update Rebate
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={handleCreateRebate}>
          Create Rebate
        </Button>
      )}
    </>
  );
};

export default RebateForm;
