import React from 'react';
import { FormControlLabel, Checkbox, Button, Grid, GridSize, Typography } from '@mui/material';
import { SelectOption } from '../../form/model';
import TextInput from '../../form/components/TextInput';
import FilterSelectInput from '../../form/components/FilterSelectInput';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface CostProfileFilterBarProps {
  filterValues?: any;
  setFilterValues?: any;
}

const CostProfileFilterBar: React.FC<CostProfileFilterBarProps> = (props) => {
  const { filterValues, setFilterValues } = props;

  const handleApplyFilters = async () => {};

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12} md={2}>
        <FilterSelectInput
          value={filterValues?.l2_category}
          choices={[{ label: 'Packaged unit', value: 'Packaged unit' }]}
          onChange={(value) => setFilterValues({ ...filterValues, l2_category: value })}
          label="Specifx L2 Description"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FilterSelectInput
          value={filterValues?.l3_category}
          choices={[{ label: 'Single zone, constant air volume', value: 'Single zone, constant air volume' }]}
          onChange={(value) => setFilterValues({ ...filterValues, l3_category: value })}
          label="Specifx L3 Description"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff', marginTop: 19 }}
          disableElevation
          onClick={() => {
            handleApplyFilters();
          }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

export default CostProfileFilterBar;
