import React from 'react';
import axios from '../../utils/axios.utils';
import { Rebate, RebateFormValues } from '../../estimateRebates/model';
import { SelectOption } from '../../form/model';

interface RebatesContextInterface {
  fetchRebates?: (params?: any) => void;
  rebates?: Rebate[];
  rebatesLoading?: boolean;

  fetchRebate?: (rebate_id: string) => void;
  rebate?: Rebate;
  rebateLoading?: boolean;

  fetchChoices?: () => void;
  manufacturerChoices?: SelectOption[];

  formOptions?: any;

  createRebate?: (formValues: RebateFormValues, onSuccess: () => void) => void;
  updateRebate?: (rebateId: string, formValues: RebateFormValues, onSuccess: () => void) => void;
  rebateSubmitting?: boolean;
}

interface RebatesContextProviderProps {
  children: React.ReactNode;
}

const RebatesContext = React.createContext<RebatesContextInterface>({});

const RebatesContextConsumer = RebatesContext.Consumer;
const RebatesContextProvider: React.FC<RebatesContextProviderProps> = ({ children }) => {
  const [rebates, setRebates] = React.useState<Rebate[]>([]);
  const [rebatesLoading, setRebatesLoading] = React.useState<boolean>(true);

  const [formOptions, setFormOptions] = React.useState<any>({});

  const [rebate, setRebate] = React.useState<Rebate>({});
  const [rebateLoading, setRebateLoading] = React.useState<boolean>(true);

  const [manufacturerChoices, setManufacturerChoices] = React.useState<SelectOption[]>([]);
  const [locationChoices, setLocationChoices] = React.useState<SelectOption[]>([]);

  const [rebateSubmitting, setRebateSubmitting] = React.useState<boolean>(false);

  const fetchRebates = async () => {
    const response = await axios.get<string, any>('/rebates');

    setRebates(response?.data?.result);
    setRebatesLoading(false);
  };

  const fetchRebate = async (rebate_id: string) => {
    const response = await axios.get<string, any>(`/rebates/${rebate_id}`);

    setRebate(response?.data?.result);
    setRebateLoading(false);
  };

  const fetchChoices = async () => {
    const response = await axios.get<string, any>('/rebates/form_options');
    setFormOptions(response?.data);
  };

  const createRebate = async (formValues: RebateFormValues, onSucess: () => void) => {
    setRebateSubmitting(true);

    await axios
      .post<RebateFormValues, any>('/rebates/', { ...formValues })
      .then((response) => {
        onSucess();
      })
      .catch((error) => {
        setRebateSubmitting(false);
      });
  };

  const updateRebate = async (rebateId: string, formValues: RebateFormValues, onSucess: () => void) => {
    setRebateSubmitting(true);

    await axios
      .put<RebateFormValues, any>(`/rebates/${rebateId}`, { ...formValues })
      .then((response) => {
        onSucess();
      })
      .catch((error) => {
        setRebateSubmitting(false);
      });
  };

  return (
    <RebatesContext.Provider
      value={{
        fetchRebates,
        rebates,
        rebatesLoading,

        fetchRebate,
        rebate,
        rebateLoading,

        fetchChoices,
        manufacturerChoices,

        formOptions,

        createRebate,
        updateRebate,
        rebateSubmitting,
      }}
    >
      {children}
    </RebatesContext.Provider>
  );
};

export { RebatesContextProvider, RebatesContextConsumer, RebatesContext };
