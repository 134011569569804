import React, { ChangeEvent } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { EquipmentCostsContext } from '../contexts/EquipmentCostsContext';
import { EquipmentCostFormValues, CostProfile } from '../model';

interface EquipmentCostFormProps {
  costProfile?: CostProfile;
  onSuccess?: () => void;
}

const EquipmentCostForm: React.FunctionComponent<EquipmentCostFormProps> = (props) => {
  const { costProfile, onSuccess } = props;
  const [formValues, setFormValues] = React.useState<EquipmentCostFormValues>();
  const [choicesFetched, setChoicesFetched] = React.useState(false);

  const { createCostProfile, updateCostProfile, fetchChoices, equipmentCostFormChoices } =
    React.useContext(EquipmentCostsContext);

  React.useEffect(() => {
    setFormValues(costProfile);
  }, [costProfile]);

  React.useEffect(() => {
    if (!choicesFetched) {
      fetchChoices();
      setChoicesFetched(true);
    }
  }, [fetchChoices, choicesFetched, setChoicesFetched]);

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleCreateCostProfile = () => {
    createCostProfile(formValues, onSuccess);
  };

  const handleUpdateCostProfile = () => {
    updateCostProfile(costProfile.id, formValues, onSuccess);
  };

  return (
    <>
      <br />
      <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Category"
              value={formValues?.category}
              choices={[
                { label: 'Installation Cost', value: 'installation' },
                { label: 'Equipment Cost', value: 'equipment' },
              ]}
              valueChanged={(value) => setFormValues({ ...formValues, category: value.trim() })}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Manufacturer"
              value={formValues?.manufacturer_id}
              choices={equipmentCostFormChoices?.manufacturers}
              valueChanged={(value) => setFormValues({ ...formValues, manufacturer_id: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Specifx L2 Description"
              shrinkLabel
              value={'Package Unit'}
              valueChanged={(value) => setFormValues({ ...formValues, asset_schema: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Specifx L3 Description"
              shrinkLabel
              value={'Single zone, constant air volume'}
              valueChanged={(value) => setFormValues({ ...formValues, asset_schema: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Asset Type"
              value={formValues?.asset_type_id}
              choices={equipmentCostFormChoices?.asset_types}
              valueChanged={(value) => setFormValues({ ...formValues, asset_type_id: value.trim() })}
            />
          </Grid>
          <br />
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Voltage V/P"
              shrinkLabel
              value={formValues?.asset_type_id}
              valueChanged={(value) => setFormValues({ ...formValues, asset_type_id: value.trim() })}
            />
          </Grid>
          <br />
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Default Cost Per Ton"
              shrinkLabel
              value={formValues?.default_cost}
              valueChanged={(value) => setFormValues({ ...formValues, default_cost: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Minimum Asset Size"
              shrinkLabel
              value={formValues?.minimum_size}
              valueChanged={(value) => setFormValues({ ...formValues, minimum_size: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Maximum Asset Size"
              shrinkLabel
              value={formValues?.maximum_size}
              valueChanged={(value) => setFormValues({ ...formValues, maximum_size: value.trim() })}
            />
          </Grid>
        </Grid>
      </form>
      {/* {console.log(formValues)} */}
      {formValues?.id ? (
        <Button variant="contained" color="primary" onClick={handleUpdateCostProfile}>
          Update Equipment Cost
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={handleCreateCostProfile}>
          Create Equipment Cost
        </Button>
      )}
    </>
  );
};

export default EquipmentCostForm;
