/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { SalesEstimate } from '../../estimateDetails/model';
import TudiLetterhead from '../../../../src/assets/TudiLetterheadTop.jpeg';
import { termsAndConditions } from './termsAndConditions';

type Props = {
  salesEstimate: SalesEstimate;
};

const today = new Date();

const formattedDate = today.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

// These styles were copied from the CSIP Generator app.
// Many of them can probably be deleted after we have the appearance of the PDF finalized.
const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  contentContainer: {
    padding: 20,
  },
  body: {
    font: 'Raleway',
    fontSize: 12,
    height: '100%',
    width: '80%',
  },
  termsAndConditionsView: {
    marginBottom: 10, // Space between items
    flexDirection: 'column', // Stack children vertically
    paddingBottom: 5, // Padding at the bottom
  },
  termsAndConditions: {
    font: 'Raleway',
    fontSize: 10,
    textAlign: 'justify',
  },
  coloredStripe: {
    backgroundColor: 'maroon',
    height: 7,
    marginBottom: 5,
  },
  columnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  costLabelColumn: {
    width: '48%',
    marginLeft: '20%',
  },
  costValueColumn: {
    width: '18%',
    marginRight: '20%',
  },
  flexCenterHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
  },
  footer: { textAlign: 'right', marginBottom: 15, color: 'white', fontSize: 8, padding: 5 },
  footerContainer: {
    position: 'absolute',
    bottom: 10,
    width: '100%',
    backgroundColor: 'black',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
  },
  header: {},
  headerFooterText: { fontSize: 12, fontWeight: 700, textTransform: 'uppercase' },
  horizontalRule: {
    width: 235,
    height: 3,
    left: 278,
    top: 4,
    position: 'absolute',
  },
  letterheadImage: {
    width: '100%',
    height: 50,
  },
  listItem: {
    flexDirection: 'row',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 20,
    fontSize: 12,
  },
  bulletPoint: {
    width: 10, // Adjust the width for bullet alignment
    textAlign: 'right',
  },
  subListItem: {
    flexDirection: 'row',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 25,
    fontSize: 11,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  sectionHeader: {
    display: 'flex',
    fontFamily: 'Raleway',
    padding: '0 50px',
  },
  pageFooter: {
    backgroundColor: 'black',
  },
  pageFooterText: {
    color: 'white',
    fontSize: 10,
    textAlign: 'center',
  },
});

function EstimatePDF(props: Props) {
  const { salesEstimate } = props;

  const FooterComponent = (currentPage: number, pageCount: number) => {
    return (
      <View style={styles.footerContainer} fixed>
        <Text style={styles.footer}>343 Munson Ave.</Text>
        <Text style={styles.footer}>McKees Rocks, PA, 15136</Text>
        <Text style={styles.footer}>P: 412-771-4100</Text>
        <Text style={styles.footer}>www.tudi.com</Text>
        <Text style={styles.footer}>
          Page {currentPage} | {pageCount}
        </Text>
      </View>
    );
  };

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <Image src={TudiLetterhead} style={styles.letterheadImage} />
        <View style={styles.contentContainer} fixed>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.headerFooterText}>{formattedDate}</Text>
            <Text style={styles.headerFooterText}>Quote Number {salesEstimate?.id}</Text>
          </View>
          <View style={styles.header} fixed>
            <Text style={styles.headerFooterText}>{salesEstimate?.customer_name}</Text>
            <Text style={styles.headerFooterText}>{salesEstimate?.site?.name}</Text>
            <Text style={styles.headerFooterText}>{salesEstimate?.site?.address1}</Text>
            <Text style={styles.headerFooterText}>
              {salesEstimate?.site?.city}, {salesEstimate?.site?.state} {salesEstimate?.site?.zip}
            </Text>
          </View>
        </View>
        <View style={styles.coloredStripe} />
        <View style={styles.contentContainer} fixed>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}>
              We are pleased to submit our proposal to replace a rooftop unit at your facility that has active repairs.
              This unit is at useful life and the coil may not be able to be brazed, resulting in additional costly
              repairs.
            </Text>
          </View>

          {/* List */}
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}>The following is included in our scope of work:</Text>
          </View>
          <View style={styles.listItem} fixed>
            <Text style={styles.listItem}>• Remove and dispose of existing equipment</Text>
          </View>
          <View style={styles.listItem} fixed>
            <Text style={styles.listItem}>• Provide and install one (1) new Carrier packaged rooftop unit</Text>
          </View>
          <View style={styles.subListItem} fixed>
            <Text style={styles.listItem}>o 6 ton</Text>
          </View>
          <View style={styles.subListItem} fixed>
            <Text style={styles.listItem}>o 208/230V 3ph</Text>
          </View>
          <View style={styles.subListItem} fixed>
            <Text style={styles.listItem}>o R410a refrigerant</Text>
          </View>

          {/* After List */}
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}>Total Installed Cost: {salesEstimate?.total_costs}</Text>
          </View>

          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}>
              Total Installed Cost (for two units): {parseFloat(salesEstimate?.total_costs) * 2}
            </Text>
          </View>

          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}>
              Please feel free to contact me at (412) 771-4100 or Emily.Srock@tudi.com if you have any questions or
              concerns. I look forward to hearing from you.
            </Text>
          </View>
        </View>
        {FooterComponent(1, 2)}
      </Page>
      <Page size="LETTER" style={styles.page}>
        <Image src={TudiLetterhead} style={styles.letterheadImage} />
        <View style={styles.contentContainer} fixed>
          {termsAndConditions.map((item) => (
            <View style={styles.termsAndConditionsView} fixed>
              <Text style={styles.termsAndConditions}>{item}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}

export default EstimatePDF;
