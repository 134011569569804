export const termsAndConditions = [
  '1. Invoices are payable by Customer within the specified terms in this contract. In the event an invoice becomes forty-five (45) days or more past due, Tudi Mechanical may immediately cease all work and/or terminate this Agreement without notice or liability, and the entire Agreement price shall thereupon become due and payable.',
  '2. Tudi Mechanical shall perform all services during its normal business hours as defined as Monday through Friday (excluding federal holidays) from 7 AM to 5 PM.',
  '3. In no event shall Tudi Mechanical be required to alter, move or replace any structural portion of the Customer’s premises, and Customer shall afford Tudi Mechanical free and prompt access to all areas and systems along with the ability to cease or initiate operation of the equipment.',
  '4. The Agreement price shall not include any alterations causing extra work, materials or labor, each of which shall automatically become an extra charge reflecting a negotiated fixed-price amount or a time-and-materials cost based on Tudi Mechanical’s then prevailing rates.',
  '5. Customer acknowledges and agrees that Tudi Mechanical has no liability or other responsibility for system design, repairs or replacements caused by weather or Acts of God, obsolescence, electrical power failures or burned out fuses, safety tests, valve body and damper removal or installment, low voltage or water pressure, misuse, abuse or improper operation of systems, negligence of Customer or others, vandalism, governmental, legal or insurance requirements, or any other causes beyond Tudi Mechanical’s control.',
  '6. Tudi Mechanical’s liability for any nonperformance, other misconduct or negligence, with respect to the Agreement, is limited to repair or replacement at its sole option, which Customer acknowledges to be its sole remedy for all purposes under the Agreement. This remedy shall not be available after the term of the Agreement, and shall not apply if failure is due, in whole or in part, to abuse, misuse, accident or modifications.',
  '7. In no event shall Tudi Mechanical be liable for any lost profits or use, increased maintenance or operating costs, any claims by customer’s clients or tenants, or any special, indirect or consequential damages whatsoever, whether arising in contract, tort, equity or otherwise.',
  '8. Tudi Mechanical also shall not be liable for any loss, damage, delay or detention in whole or in part caused by delays of carriers, strikes, unavailability of machinery, equipment or materials, lockouts, civil or military authorities, priority regulations, insurrection or riot, forces of nature or Acts of God or any other event beyond its control.',
  '9. In no event shall Tudi Mechanical be responsible for identifying, handling, abating or removing asbestos or any other hazardous or toxic substances, wastes or materials. Upon encountering any such substances, Tudi Mechanical shall have the absolute right to cease all work unless and until such substances are removed, in which case both the time for completing the work and the Agreement price shall be adjusted to fairly reflect such interruption. Customer shall furnish all relevant Material Safety Data Sheets under OSHA’s Hazard Communication Standard Regulations to Tudi Mechanical’s personnel.',
  '10. Customer must commence any legal action against Tudi Mechanical for any reason whatsoever relating to the Agreement no later than one (1) year after the Commencement Date of the work. Customer agrees to pay all of Tudi Mechanical’s attorneys’ fees and court costs for any legal action brought by Tudi Mechanical to recover amounts due it under the Agreement.',
  '11. Customer agrees to indemnify, defend and hold harmless Tudi Mechanical, its officers, agents and employees, from and against any and all claims, causes of action, damages, losses and expenses, including but not limited to court costs and attorneys’ fees, arising out of or resulting from the performance of the work hereunder, provided that such claim, cause of action, loss or damage is caused in whole or in part by an active or passive act or omission of Customer, its direct or indirect employee, or any other person or entity for whole acts Customer may be liable, whether or not in part due to Tudi Mechanical’s negligence.',
  '12. Customer shall be responsible for all taxes applicable to services and/or materials hereunder (included in contract price).',
];
