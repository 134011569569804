import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

const MicrosoftAuthPage: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  return (
    <div style={{ marginTop: 100, marginLeft: 20, marginRight: 20 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ textAlign: 'center', marginLeft: -100 }}>
          <img
            style={{ height: 40 }}
            alt="logo"
            src="https://www.tudi.com/wp-content/themes/boxpress/assets/img/dist/branding/tudi-mechanical-logo@2x.png"
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ marginBottom: '60px', textAlign: 'center' }}>
          {isAuthenticated ? (
            <div style={{ marginTop: '60px' }}>
              <h1>Authentication Successful!</h1>
              <p>Welcome to the application.</p>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.push('/rooftop_automation/recent')}
                style={{ marginLeft: '10px' }}
              >
                Back to Estimates
              </Button>
            </div>
          ) : (
            <div style={{ marginTop: '60px' }}>
              <h1>Authentication Failed</h1>
              <p>Please try logging in again.</p>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.push('/login')}
                style={{ marginLeft: '10px' }}
              >
                Back to Login
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default MicrosoftAuthPage;
