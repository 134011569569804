import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, Button, Typography } from '@mui/material';
import TextInput from '../../form/components/TextInput';
import axios from '../../utils/axios.utils';

function UtilityProvidersList() {
  const history = useHistory();

  const [utilityProviders, setUtilityProviders] = useState<any[]>([]);
  const [utilityProvidersFetched, setUtilityProvidersFetched] = useState<boolean>(false);
  const [addProviderButtonShowing, setAddProviderButtonShowing] = useState<boolean>(false);
  const [newProviderName, setNewProviderName] = useState<string>('');

  const fetchUtilityProviders = async (params: any = {}) => {
    axios
      .get<string, any>('/utility_providers')
      .then((response) => {
        setUtilityProviders(response?.data?.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (!utilityProvidersFetched) {
    fetchUtilityProviders();
    setUtilityProvidersFetched(true);
  }

  const handleNewProvider = () => {
    axios
      .post<any>('/utility_providers', { name: newProviderName })
      .then((response) => {
        fetchUtilityProviders();
        setAddProviderButtonShowing(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Typography variant="h4">Utility Providers</Typography>
      </Grid>

      {addProviderButtonShowing ? (
        <Grid container spacing={2} alignItems="center" style={{ marginTop: 15 }}>
          <Grid item xs={3}>
            <TextInput
              value={newProviderName}
              type="text"
              label="Name"
              valueChanged={(value: any) => setNewProviderName(value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="warning"
              disabled={newProviderName?.length < 3}
              onClick={handleNewProvider}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="secondary" onClick={() => setAddProviderButtonShowing(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={4}>
          <Button variant="contained" color="primary" onClick={() => setAddProviderButtonShowing(true)}>
            Add Provider
          </Button>
        </Grid>
      )}
      <Grid item xs={12}></Grid>
      {utilityProviders?.map((utilityProvider) => (
        <Grid
          item
          xs={4}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          key={utilityProvider.id}
        >
          <Card
            key={utilityProvider}
            style={{ padding: 10, cursor: 'pointer' }}
            onClick={() => history.push(`/admin/utility_providers/${utilityProvider.id}`)}
          >
            <p style={{ margin: 0 }}>{utilityProvider.name}</p>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default UtilityProvidersList;
