import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../utils/axios.utils';
import { SystemAccessoryFormValues, SystemAccessory } from '../model';
import { SelectOption } from '../../form/model';

interface SystemAccessoryFormOptions {
  asset_categories: SelectOption[];
}

interface SystemAccessoriesContextInterface {
  fetchSystemAccessories?: () => void;
  systemAccessories?: SystemAccessory[];
  systemAccessoriesLoading?: boolean;

  fetchSystemAccessory?: (systemAccessory_id: string) => void;
  systemAccessory?: SystemAccessory;
  systemAccessoryLoading?: boolean;

  fetchChoices?: () => void;
  manufacturerChoices?: SelectOption[];

  formOptions?: SystemAccessoryFormOptions;
  setFormOptions?: Dispatch<SetStateAction<SystemAccessoryFormOptions>>;

  updateSystemAccessory?: (
    systemAccessoryId: string,
    formValues: SystemAccessoryFormValues,
    onSuccess: () => void,
  ) => void;
  systemAccessorySubmitting?: boolean;
}

interface SystemAccessoriesContextProviderProps {
  children: React.ReactNode;
}

const SystemAccessoriesContext = React.createContext<SystemAccessoriesContextInterface>({});

const SystemAccessoriesContextConsumer = SystemAccessoriesContext.Consumer;
const SystemAccessoriesContextProvider: React.FC<SystemAccessoriesContextProviderProps> = ({ children }) => {
  const [systemAccessories, setSystemAccessories] = React.useState<SystemAccessory[]>([]);
  const [systemAccessoriesLoading, setSystemAccessoriesLoading] = React.useState<boolean>(true);

  const [systemAccessory, setSystemAccessory] = React.useState<SystemAccessory>({});
  const [systemAccessoryLoading, setSystemAccessoryLoading] = React.useState<boolean>(true);

  const [manufacturerChoices, setManufacturerChoices] = React.useState<SelectOption[]>([]);
  const [locationChoices, setLocationChoices] = React.useState<SelectOption[]>([]);

  const [formValues, setFormValues] = React.useState<SystemAccessoryFormValues>({});
  const [formOptions, setFormOptions] = React.useState<SystemAccessoryFormOptions>({ asset_categories: [] });

  const [systemAccessorySubmitting, setSystemAccessorySubmitting] = React.useState<boolean>(false);

  const fetchSystemAccessories = async () => {
    const response = await axios.get<string, any>('/system_accessories');

    setSystemAccessories(response?.data?.result);
    setSystemAccessoriesLoading(false);
  };

  const fetchSystemAccessory = async (systemAccessory_id: string) => {
    const response = await axios.get<string, any>(`/system_accessories/${systemAccessory_id}`);

    setSystemAccessory(response?.data?.result);
    console.log(response?.data?.result);
    setSystemAccessoryLoading(false);
  };

  const fetchChoices = async () => {
    const response = await axios.get<string, any>('/system_accessories/form_options');
    setFormOptions(response?.data);
  };

  const updateSystemAccessory = async (
    systemAccessoryId: string,
    formValues: SystemAccessoryFormValues,
    onSuccess: () => void,
  ) => {
    setSystemAccessorySubmitting(true);

    await axios
      .put<SystemAccessoryFormValues, any>(`/system_accessories/${systemAccessoryId}`, { ...formValues })
      .then((response) => {
        if (formValues.submittal_pdf) {
          const formData = new FormData();
          formData.append('submittal_pdf', formValues.submittal_pdf);

          axios
            .post(`system_accessories/${systemAccessoryId}/upload_submittal`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              onSuccess();
            })
            .catch((error) => {
              console.error('Error uploading file: ', error);
              setSystemAccessorySubmitting(false);
            });
        }
      })
      .catch((error) => {
        setSystemAccessorySubmitting(false);
      });
  };

  return (
    <SystemAccessoriesContext.Provider
      value={{
        fetchSystemAccessories,
        systemAccessories,
        systemAccessoriesLoading,

        fetchSystemAccessory,
        systemAccessory,
        systemAccessoryLoading,

        fetchChoices,
        manufacturerChoices,

        formOptions,
        setFormOptions,

        updateSystemAccessory,
        systemAccessorySubmitting,
      }}
    >
      {children}
    </SystemAccessoriesContext.Provider>
  );
};

export { SystemAccessoriesContextProvider, SystemAccessoriesContextConsumer, SystemAccessoriesContext };
