import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, CardContent, Button, Typography } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import TextInput from '../../form/components/TextInput';
import axios from '../../utils/axios.utils';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

function ZipCodesList() {
  const history = useHistory();

  const [zipCodes, setZipCodes] = useState<any[]>([]);
  const [zipCodesFetched, setZipCodesFetched] = useState<boolean>(false);
  const [addZipButtonShowing, setAddZipButtonShowing] = useState<boolean>(false);
  const [newZipValue, setNewZipValue] = useState<string>('');

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Zip',
      selector: 'zip',
      grow: 0.5,
      sortable: true,
    },
    {
      name: 'Valid Utility Providers',
      selector: 'zip',
      grow: 1.5,
      sortable: true,
      cell: (row) => <p></p>,
    },
    {
      name: 'Total Customers',
      selector: 'zip',
      sortable: true,
      cell: (row) => <p>0</p>,
    },
    {
      name: 'Total Sites',
      selector: 'zip',
      sortable: true,
      cell: (row) => <p>0</p>,
    },
    {
      name: 'Total Estimates',
      selector: 'zip',
      sortable: true,
      cell: (row) => <p>0</p>,
    },
  ];

  const fetchZipCodes = async (params: any = {}) => {
    axios
      .get<string, any>('/zip_codes')
      .then((response) => {
        setZipCodes(response?.data?.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (!zipCodesFetched) {
    fetchZipCodes();
    setZipCodesFetched(true);
  }

  const handleNewZip = () => {
    axios
      .post<any>('/zip_codes', { zip: newZipValue })
      .then((response) => {
        fetchZipCodes();
        setAddZipButtonShowing(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">Zip Codes</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setAddZipButtonShowing(true)}>
              Add Zip Code
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {addZipButtonShowing && (
        <Grid container spacing={2} alignItems="center" style={{ marginTop: 15 }}>
          <Grid item xs={3}>
            <TextInput
              value={newZipValue}
              type="text"
              label="New Zip Code"
              valueChanged={(value: any) => setNewZipValue(value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="warning" disabled={newZipValue?.length !== 5} onClick={handleNewZip}>
              Save
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="secondary" onClick={() => setAddZipButtonShowing(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <DataTable
              title="Zip Codes"
              columns={columns}
              data={zipCodes}
              onRowClicked={(row) => history.push(`/admin/zip_codes/${row.zip}`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ZipCodesList;
