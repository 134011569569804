import React from 'react';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { SystemAccessoriesContext } from '../contexts/SystemAccessoriesContext';
import SystemAccessoryForm from '../components/SystemAccessoryForm';

const SystemAccessoryEdit: React.FunctionComponent = () => {
  const { systemAccessoryId } = useParams<Record<string, string>>();
  const history = useHistory();
  const [systemAccessoryFetched, setSystemAccessoryFetched] = React.useState(false);
  const { fetchSystemAccessory, systemAccessory, systemAccessoryLoading } = React.useContext(SystemAccessoriesContext);

  React.useEffect(() => {
    if (!systemAccessoryFetched) {
      fetchSystemAccessory(systemAccessoryId);
      setSystemAccessoryFetched(true);
    }
  }, []);

  return systemAccessoryLoading && systemAccessory?.id ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading System Accessories...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
        onClick={() => history.push('/admin/system_accessories')}
      >
        ← Return to System Accessoriess
      </Typography>
      <Typography variant="h5" component="h5">
        Edit System Accessories Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <SystemAccessoryForm
            systemAccessory={systemAccessory}
            onSuccess={() => history.push('/admin/system_accessories')}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SystemAccessoryEdit;
