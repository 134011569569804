import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DataTable from '../../dataTable/DataTable/DataTable';
import RecentEstimatesFilterBar from '../components/RecentEstimatesFilterBar';
import { EstimateDetailsContext } from '../contexts/EstimateDetailsContext';
import RecentEstimatesDeleteModal from '../components/RecentEstimatesDeleteModal';
import RecentEstimatesCopyModal from '../components/RecentEstimatesCopyModal';
import { SalesEstimate } from '../model';
import { salesEstimateColumns } from '../constants/salesEstimateColumns';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const RecentEstimates: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const [salesEstimatesFetched, setSalesEstimatesFetched] = useState<boolean>(false);

  const {
    salesEstimates,
    fetchSalesEstimates,
    setRecentEstimatesDeleteModalOpen,
    setRecentEstimatesCopyModalOpen,
    setFocusEstimate,
  } = useContext(EstimateDetailsContext);

  useEffect(() => {
    if (!salesEstimatesFetched) {
      fetchSalesEstimates();
      setSalesEstimatesFetched(true);
    }
  }, [salesEstimatesFetched]);

  const handleDelete = (row: SalesEstimate) => {
    setFocusEstimate(row);
    setRecentEstimatesDeleteModalOpen(true);
  };

  const handleDuplicate = (row: SalesEstimate) => {
    setFocusEstimate(row);
    setRecentEstimatesCopyModalOpen(true);
  };

  const columns = salesEstimateColumns(handleDelete, handleDuplicate);

  return (
    <Box className={classes.box}>
      <RecentEstimatesDeleteModal afterDelete={fetchSalesEstimates} />
      <RecentEstimatesCopyModal />
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h4" component="h4">
              Recent Estimates
            </Typography>
          </Grid>
        </Grid>
        <br />
        <RecentEstimatesFilterBar />
        <br />
        <Card>
          <CardContent>
            <DataTable
              title=""
              columns={columns}
              data={salesEstimates}
              onRowClicked={(row) => history.push(`/rooftop_automation/${row.id}/review`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default RecentEstimates;
