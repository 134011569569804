import React from 'react';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { EquipmentCostsContext } from '../contexts/EquipmentCostsContext';
import EquipmentCostForm from '../components/EquipmentCostForm';

const EquipmentCostEdit: React.FunctionComponent = () => {
  const { equipmentCostId } = useParams<Record<string, string>>();
  const history = useHistory();
  const [costProfileFetched, setCostProfileFetched] = React.useState(false);
  const { fetchEquipmentCost, costProfile, costProfileLoading } = React.useContext(EquipmentCostsContext);

  React.useEffect(() => {
    if (equipmentCostId && !costProfileFetched) {
      fetchEquipmentCost(equipmentCostId);
      setCostProfileFetched(true);
    }
  }, [equipmentCostId]);

  return costProfileLoading && costProfile?.id ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading Equipment Cost...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
        onClick={() => history.push('/admin/equipment_costs')}
      >
        ← Return to Equipment Costs
      </Typography>
      <Typography variant="h5" component="h5">
        Edit Equipment Cost Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <EquipmentCostForm costProfile={costProfile} onSuccess={() => history.push('/admin/equipment_costs')} />
        </Grid>
      </Grid>
    </>
  );
};

export default EquipmentCostEdit;
