import React from 'react';
import axios from '../../utils/axios.utils';
import { SelectOption } from '../../form/model';

import { CurbAdapter } from '../../estimateDetails/model';

interface EstimateCostsContextInterface {
  fetchCraneDetails?: (sales_estimate_id: string) => void;
  updateCraneDetails?: (sales_estimate_id: string, crane_details: Record<string, string>) => void;
  craneDetails?: Record<string, string>;

  updateCurbAdapter?: (sales_estimate_asset_id: string, curb_adapter_details: CurbAdapter) => void;

  fetchEquipmentCostOptions?: (filterValues?: any) => void;
  equipmentCostOptions?: SelectOption[];
  equipmentCostOptionsLoading?: boolean;

  fetchInstallationCosts?: () => void;
  installationCosts?: SelectOption[];
  installationCostsLoading?: boolean;

  deleteInstallationCostRecord?: (installationCostRecordId: string) => void;
}

interface EstimateCostsContextProviderProps {
  children: React.ReactNode;
}

const EstimateCostsContext = React.createContext<EstimateCostsContextInterface>({});

const EstimateCostsContextConsumer = EstimateCostsContext.Consumer;
const EstimateCostsContextProvider: React.FC<EstimateCostsContextProviderProps> = ({ children }) => {
  const [craneDetails, setCraneDetails] = React.useState({});
  const [curbAdapters, setCurbAdapters] = React.useState([]);

  const [equipmentCostOptions, setEquipmentCostOptions] = React.useState<SelectOption[]>([]);
  const [equipmentCostOptionsLoading, setEquipmentCostOptionsLoading] = React.useState<boolean>(true);

  const [installationCosts, setInstallationCosts] = React.useState<SelectOption[]>([]);
  const [installationCostsLoading, setInstallationCostsLoading] = React.useState<boolean>(true);

  const fetchCraneDetails = async (sales_estimate_id: string) => {
    const response = await axios.get<string, any>(`/sales_estimates/${sales_estimate_id}/crane_details`);

    setCraneDetails(response?.data?.result);
  };

  const updateCraneDetails = async (sales_estimate_id: string, crane_details: Record<string, string>) => {
    const response = await axios.post<any, any>(`/sales_estimates/${sales_estimate_id}/crane_details`, {
      crane_detail: { ...crane_details },
    });

    setCraneDetails(response?.data?.result);
  };

  const updateCurbAdapter = async (sales_estimate_asset_id: string, curb_adapter_details: CurbAdapter) => {
    const response = await axios.post<any, any>(`/sales_estimate_assets/${sales_estimate_asset_id}/curb_adapters`, {
      curb_adapter: { ...curb_adapter_details },
    });

    setCurbAdapters(response?.data?.result);
  };

  const fetchEquipmentCostOptions = async (filterValues?: any) => {
    const response = await axios.get<string, any>('/equipment_costs', { params: filterValues });
    setEquipmentCostOptions(response?.data?.result);
  };

  const fetchInstallationCosts = async () => {
    const response = await axios.get<string, any>('/installation_cost_records/form_options');

    setInstallationCosts(response?.data?.installation_cost_options);
  };

  const deleteInstallationCostRecord = async (installationCostRecordId: string) => {
    try {
      const response = await axios.delete<string, any>(`/installation_cost_records/${installationCostRecordId}`);

      window.location.reload();
    } catch (error: any) {
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        // Handle server or validation errors
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };

  return (
    <EstimateCostsContext.Provider
      value={{
        fetchCraneDetails,
        updateCraneDetails,
        craneDetails,

        updateCurbAdapter,

        fetchEquipmentCostOptions,
        equipmentCostOptions,
        equipmentCostOptionsLoading,

        fetchInstallationCosts,
        installationCosts,
        installationCostsLoading,

        deleteInstallationCostRecord,
      }}
    >
      {children}
    </EstimateCostsContext.Provider>
  );
};

export { EstimateCostsContextProvider, EstimateCostsContextConsumer, EstimateCostsContext };
