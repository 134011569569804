import React from 'react';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { InstallationCostsContext } from '../contexts/InstallationCostsContext';
import InstallationCostForm from '../components/InstallationCostForm';

const InstallationCostEdit: React.FunctionComponent = () => {
  const { installationCostId } = useParams<Record<string, string>>();
  const history = useHistory();
  const [costProfileFetched, setCostProfileFetched] = React.useState(false);
  const { fetchInstallationCost, installationCost, installationCostLoading } =
    React.useContext(InstallationCostsContext);

  React.useEffect(() => {
    if (!costProfileFetched && installationCostId) {
      fetchInstallationCost(installationCostId);
      setCostProfileFetched(true);
    }
  }, []);

  return installationCostLoading && installationCost?.id ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading Equipment Cost...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
        onClick={() => history.push('/admin/installation_costs')}
      >
        ← Return to Installation Costs
      </Typography>
      <Typography variant="h5" component="h5">
        Edit Installation Cost Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <InstallationCostForm
            costProfile={installationCost}
            onSuccess={() => history.push('/admin/installation_costs')}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InstallationCostEdit;
