import React from 'react';
import axios from '../../utils/axios.utils';
import { SalesEstimate } from '../../estimateDetails/model';
import { SelectOption } from '../../form/model';

interface EstimateRebatesContextInterface {
  rebates?: any[];
}

interface EstimateRebatesContextProviderProps {
  children: React.ReactNode;
}

const EstimateRebatesContext = React.createContext<EstimateRebatesContextInterface>({});

const EstimateRebatesContextConsumer = EstimateRebatesContext.Consumer;
const EstimateRebatesContextProvider: React.FC<EstimateRebatesContextProviderProps> = ({ children }) => {
  const [rebates, setRebates] = React.useState<any[]>([]);

  return (
    <EstimateRebatesContext.Provider
      value={{
        rebates,
      }}
    >
      {children}
    </EstimateRebatesContext.Provider>
  );
};

export { EstimateRebatesContextProvider, EstimateRebatesContextConsumer, EstimateRebatesContext };
