import React from 'react';

import Grid from '@mui/material/Grid';

import { SalesEstimateKeyDetails } from '../model';

import InformationField from '../../form/components/InformationField';

interface KeyDetailsEditProps {
  keyDetails: SalesEstimateKeyDetails;
}

const trueToYes = (value: boolean) => (value ? 'Yes' : 'No');

const styles = {
  keyDetailsGrid: {
    marginBottom: 20,
  },
};

const KeyDetailsView: React.FC<KeyDetailsEditProps> = (props) => {
  let { keyDetails } = props;

  return (
    <Grid container>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Folder Clean" value={trueToYes(keyDetails?.folder_clean)} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Tax Exempt" value={trueToYes(keyDetails?.tax_exempt)} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Night Work" value={trueToYes(keyDetails?.night_work)} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Weekend Work" value={trueToYes(keyDetails?.wknd_work)} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Urgency" value={keyDetails?.urgency} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Permit Required" value={trueToYes(keyDetails?.permit_required)} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Turnover Meeting" value={keyDetails?.turnover_mtg} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Closeout Type" value={keyDetails?.closeout_type} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Startup Type" value={keyDetails?.startup_type} />
      </Grid>
      <Grid item xs={4} md={3} style={styles.keyDetailsGrid}>
        <InformationField label="Lead Tech" value={keyDetails?.lead_tech} />
      </Grid>
    </Grid>
  );
};

export default KeyDetailsView;
