import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { InstallationCostsContext } from '../contexts/InstallationCostsContext';
import DataTable from '../../dataTable/DataTable/DataTable';
import InstallationCostFilterBar from '../components/InstallationCostFilterBar';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Primary Category',
    selector: 'primary_category',
    sortable: true,
  },
  {
    name: 'Tooltip',
    selector: 'tooltip',
    sortable: true,
  },
  {
    name: 'Cost Amount',
    selector: 'formatted_default_cost',
    sortable: true,
    cell: (row) => (
      <div>
        {row.primary_category === 'material' ? (
          <>
            <p>3-5: {row.small_unit_cost}</p>
            <p>6-12.5: {row.medium_unit_cost}</p>
            <p>12.5-25: {row.large_unit_cost}</p>
          </>
        ) : (
          <p>No preset cost</p>
        )}
      </div>
    ),
  },
  {
    name: 'Default Cost',
    selector: 'asset_size_range',
    sortable: true,
    cell: (row) => <p>{row.primary_category === 'material' ? 'True' : 'False'}</p>,
  },
];

const InstallationCostsList: React.FunctionComponent = () => {
  const history = useHistory();

  const [costProfileFetched, setCostProfileFetched] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState({});
  const { fetchInstallationCosts, installationCosts, installationCostsLoading } =
    React.useContext(InstallationCostsContext);

  React.useEffect(() => {
    if (!costProfileFetched) {
      fetchInstallationCosts();
      setCostProfileFetched(true);
    }
  }, []);

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ marginTop: 5 }}>
              Installation Costs
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push('/admin/installation_costs/new')}>
              New Installation Cost +
            </Button>
          </Grid>
        </Grid>
        <br />
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <InstallationCostFilterBar filterValues={filterValues} setFilterValues={setFilterValues} />
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title="Installation Costs"
              columns={columns}
              data={installationCosts}
              onRowClicked={(row) => history.push(`/admin/installation_costs/${row.id}/edit`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default InstallationCostsList;
