import Spreadsheet from 'react-spreadsheet';

const SimpleSpreadsheet = () => {
  const data = [
    [
      { value: 'ESTIMATE SHEET - 2024' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Customer Name:' },
      { value: '' },
      { value: 'Chaska Building 220' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Salesman:' },
      { value: '' },
      { value: 'Mucci' },
      { value: '' },
      { value: '' },
      { value: 'Date:' },
      { value: '3/7/24' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Opportunity Name:', style: { backgroundColor: '#0070C0' } },
      { value: '' },
      { value: 'Replace RTU-08' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Service Tech Referral (Spiff):' },
      { value: '' },
      { value: 'Richardst' },
      { value: '' },
      { value: '' },
      { value: 'Contact:' },
      { value: 'David Donley' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Qty' },
      { value: 'Equipment' },
      { value: '' },
      { value: '' },
      { value: 'Part #' },
      { value: '' },
      { value: 'Vendor' },
      { value: '' },
      { value: 'Voltage/Phase' },
      { value: 'Tonnage' },
      { value: 'Cost Unit' },
      { value: 'Total Cost' },
      { value: '' },
      { value: 'Phone:' },
      { value: '724-799-3890' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '3 ton unit' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Notes' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: 'Economizer' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t5,400.00 ' },
      { value: ' $\t5,400.00 ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Folder Clean' },
      { value: 'Yes' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Tax Exmpt' },
      { value: 'No' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '1' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Night Work' },
      { value: 'No' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Tax @' },
      { value: '' },
      { value: '7%' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t378.00 ' },
      { value: '' },
      { value: 'Wknd Work' },
      { value: 'No' },
      { value: '' },
      { value: 'SalesForce Input' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Total Equipment Cost' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t5,778.00 ' },
      { value: '' },
      { value: 'POC' },
      { value: 'david ' },
      { value: '' },
      { value: 'Labor Hr.' },
      { value: ' \t18.00 ' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Urgency' },
      { value: 'ASAP' },
      { value: '' },
      { value: 'Labor Cost' },
      { value: ' $\t1,638.00 ' },
      { value: '' },
    ],
    [
      { value: 'Subcontractors' },
      { value: '' },
      { value: 'Cost' },
      { value: '' },
      { value: 'Material' },
      { value: '' },
      { value: 'Cost' },
      { value: '' },
      { value: 'Labor Summary' },
      { value: '' },
      { value: '' },
      { value: '$91 ' },
      { value: '' },
      { value: "Permit REQ'D" },
      { value: 'No' },
      { value: '' },
      { value: 'Material' },
      { value: ' $\t10,841.00 ' },
      { value: '' },
    ],
    [
      { value: 'Crane Rental' },
      { value: '' },
      { value: ' $\t2,000.00 ' },
      { value: '' },
      { value: 'Stat' },
      { value: '' },
      { value: ' $\t200.00 ' },
      { value: '' },
      { value: 'Labor Type' },
      { value: "Men Req'd" },
      { value: 'Hours' },
      { value: 'Cost' },
      { value: '' },
      { value: 'Turnover Mtg' },
      { value: 'Phone' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Lift Rental' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Electric' },
      { value: '' },
      { value: ' $\t300.00 ' },
      { value: '' },
      { value: 'Scope' },
      { value: '1' },
      { value: '2' },
      { value: ' $\t182.00 ' },
      { value: '' },
      { value: 'Closeout Type' },
      { value: 'None' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Demolition' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Gas' },
      { value: '' },
      { value: ' $\t400.00 ' },
      { value: '' },
      { value: 'Installer' },
      { value: '2' },
      { value: '8' },
      { value: ' $\t1,456.00 ' },
      { value: '' },
      { value: 'Startup Type' },
      { value: 'Projects' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Cost Per' },
    ],
    [
      { value: 'Electrical Sub' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Curb - CSM ONLY' },
      { value: '' },
      { value: ' $\t1,500.00 ' },
      { value: '' },
      { value: 'Helper' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: "Asset #'s" },
      { value: 'RTU8 ' },
      { value: '' },
      { value: 'Tonnage' },
      { value: '3' },
      { value: '$5,950' },
    ],
    [
      { value: 'Roofing' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'From Labor Tab' },
      { value: '' },
      { value: '0' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Lead Tech' },
      { value: 'ANY ' },
      { value: '' },
      { value: 'BTU' },
      { value: '0' },
      { value: '$5,950' },
    ],
    [
      { value: 'Insulation' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Overtime/After Hrs' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Rebate/EME?' },
      { value: '' },
      { value: '' },
      { value: 'CFM' },
      { value: '0' },
      { value: '$0' },
    ],
    [
      { value: 'Control Sub' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Startup-Project' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Other' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Total Labor Hours' },
      { value: '' },
      { value: '18.0 ' },
      { value: ' $\t1,638.00 ' },
      { value: '' },
      { value: 'Gross Profit' },
      { value: '$5,371' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Other' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Actual GP%' },
      { value: '30.09%' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Other' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Cost Summary' },
      { value: 'Total Cost' },
      { value: 'GP%' },
      { value: 'Sell Price' },
      { value: '' },
      { value: 'GPPMD' },
      { value: '$2,387' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Service-InterComp' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: 'Equipment' },
      { value: ' $\t5,778.00 ' },
      { value: '35%' },
      { value: ' $\t8,889.23 ' },
      { value: '' },
      { value: 'Plan GPPMD' },
      { value: '$1,300' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Tax @ 7%' },
      { value: '' },
      { value: ' $\t140.00 ' },
      { value: '' },
      { value: 'Tax @' },
      { value: '7%' },
      { value: ' $\t168.00 ' },
      { value: '' },
      { value: 'Material' },
      { value: ' $\t2,568.00 ' },
      { value: '35%' },
      { value: ' $\t3,950.77 ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Total Sub Cost' },
      { value: '' },
      { value: ' $\t2,140.00 ' },
      { value: '' },
      { value: 'Total Material' },
      { value: '' },
      { value: ' $\t2,568.00 ' },
      { value: '' },
      { value: 'Subcontractor' },
      { value: ' $\t2,140.00 ' },
      { value: '35%' },
      { value: ' $\t3,292.31 ' },
      { value: '' },
      { value: 'PLB Inter-Comp.' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '*Separate Price from Projects (Does not impact cost)' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Miscellaneous' },
      { value: ' $\t250.00 ' },
      { value: '35%' },
      { value: ' $\t384.62 ' },
      { value: '' },
      { value: 'CNTR Inter-Comp.' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '*Separate Price from Projects (Does not impact cost)' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Miscellaneous Summary' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Labor' },
      { value: ' $\t1,638.00 ' },
      { value: '35%' },
      { value: ' $\t2,520.00 ' },
      { value: '' },
      { value: 'EL Inter-Comp.' },
      { value: ' $\t-   ' },
      { value: '' },
      { value: '*Separate Price from Projects (Does not impact cost)' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Item' },
      { value: '' },
      { value: 'Cost' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Spiff' },
      { value: ' $\t105.00 ' },
      { value: '35%' },
      { value: ' $\t161.54 ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Miscellaneous' },
      { value: '' },
      { value: ' $\t250.00 ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: 'Total' },
      { value: ' $\t12,479.00 ' },
      { value: '35%' },
      { value: ' $\t19,198.46 ' },
      { value: '' },
      { value: 'Quoted Price:' },
      { value: ' $\t17,850.00 ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'Notes' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: 'KSC Curb is not being used - Curb to come from CSM ONLY ' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
  ];
  return <Spreadsheet data={data} />;
};

export default SimpleSpreadsheet;
