import React from 'react';
import { Helmet } from 'react-helmet';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import '@shopify/polaris/build/esm/styles.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Routes from './routes';
import ContextsProvider from './contexts';
import './App.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {},
}));

interface AppProps {
  msalInstance: any;
}

const App: React.FC<AppProps> = (props) => {
  const classes = useStyles();
  const theme = createTheme();

  const { msalInstance } = props;

  return (
    <AppProvider i18n={enTranslations}>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <ContextsProvider>
            <CssBaseline />
            <Helmet>
              <script>
                {`
                window.onUsersnapLoad = function(api) {
                  api.init();
                };
              `}
              </script>
              <script
                src="https://widget.usersnap.com/global/load/9ae6e140-c7ad-4860-adc1-258062488ed0?onload=onUsersnapLoad"
                defer
              ></script>
            </Helmet>
            <Container className={classes.root} color="background" maxWidth="xl">
              <Box className={classes.box}>
                <main role="main" className={classes.content}>
                  <Routes />
                </main>
              </Box>
            </Container>
          </ContextsProvider>
        </ThemeProvider>
      </MsalProvider>
    </AppProvider>
  );
};

export default App;
