import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

interface SalesEstimateStepperProps {
  activeStep: number;
  salesEstimateId?: string;
}

const SalesEstimateStepper: React.FC<SalesEstimateStepperProps> = (props) => {
  const { activeStep, salesEstimateId = '0' } = props;
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const steps = [
    { title: 'Customer', link: `/rooftop_automation/${salesEstimateId}/customer` },
    { title: 'System', link: `/rooftop_automation/${salesEstimateId}/system` },
    { title: 'Equipment', link: `/rooftop_automation/${salesEstimateId}/equipment` },
    { title: 'Crane/Curb', link: `/rooftop_automation/${salesEstimateId}/crane_curb` },
    { title: 'Installation', link: `/rooftop_automation/${salesEstimateId}/installation_costs` },
    { title: 'Rebates', link: `/rooftop_automation/${salesEstimateId}/rebates` },
    { title: 'Review', link: `/rooftop_automation/${salesEstimateId}/review` },
  ];

  const handleNext = () => {
    const nextStep = activeStep + 1;
    window.location.href = steps[nextStep].link;
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    window.location.href = steps[prevStep].link;
  };

  return (
    <Box sx={{ width: '100%' }}>
      {isMobile ? (
        <Box>
          <Typography variant="h6" align="center" sx={{ mb: 1 }}>
            {steps[activeStep].title}
          </Typography>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === steps.length - 1 || salesEstimateId === '0'}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0 || salesEstimateId === '0'}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
      ) : (
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={step.title}>
              <StepLabel>
                <a
                  href={salesEstimateId === '0' ? null : step.link}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {step.title}
                </a>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <br />
    </Box>
  );
};

export default SalesEstimateStepper;
