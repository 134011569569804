import React, { FC, useState, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SelectInput from '../../form/components/SelectInput';
import TextInput from '../../form/components/TextInput';
import axios from '../../utils/axios.utils';

interface InstallationCostModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  unitLabel: string;
  salesEstimate: any;
  descriptionOptions?: any[];
}

const InstallationCostModal: FC<InstallationCostModalProps> = (props) => {
  let { open, handleClose, title, unitLabel, salesEstimate, descriptionOptions = [] } = props;

  let [formValues, setFormValues] = useState<Record<string, string>>({});
  let [materialCategory, setMaterialCategory] = useState('');
  let [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    let total = parseFloat(formValues.cost_per_unit) * parseFloat(formValues.job_units) || 0;
    setTotalCost(total);
  }, [formValues]);

  let handleSubmit = async () => {
    const response = await axios.post('/installation_cost_records', {
      installation_cost_record: formValues,
    });

    window.location.reload();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New {title}</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <SelectInput
          label="Asset Number"
          value={formValues.sales_estimate_asset_id}
          valueChanged={(value) => setFormValues({ ...formValues, sales_estimate_asset_id: value })}
          choices={salesEstimate.sales_estimate_assets?.flatMap((asset) => ({
            label: asset.asset_number,
            value: asset.id,
          }))}
        />
        <br />
        <SelectInput
          label="Description"
          value={formValues.installation_cost_id}
          valueChanged={(value) => {
            setFormValues({ ...formValues, installation_cost_id: value });
            console.log(descriptionOptions.filter((option) => option.value === value));
            setMaterialCategory(descriptionOptions.filter((option) => option.value === value)[0]?.label);
          }}
          choices={descriptionOptions}
        />
        <br />
        {materialCategory === 'Other' && (
          <>
            <TextInput
              label="Other Description"
              value={formValues.description_override}
              shrinkLabel
              valueChanged={(value) => setFormValues({ ...formValues, description_override: value })}
            />
            <br />
          </>
        )}
        <TextInput
          label={`Cost per ${unitLabel}`}
          value={formValues.cost_per_unit}
          shrinkLabel
          currency
          valueChanged={(value) => setFormValues({ ...formValues, cost_per_unit: value })}
        />
        <br />
        <TextInput
          label={`${unitLabel}s`}
          value={formValues.job_units}
          shrinkLabel
          valueChanged={(value) => setFormValues({ ...formValues, job_units: value })}
        />
        <br />
        <Typography style={{ marginTop: 10 }}>Total Cost: ${totalCost?.toFixed(2) || ''}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" onClick={handleSubmit}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstallationCostModal;
