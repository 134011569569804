import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Card, Typography, Button, Grid } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

const assetColumns: IDataTableColumn<any>[] = [
  {
    name: 'Cost Subtype',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
  },
  {
    name: 'Cost',
    selector: 'cost',
    sortable: true,
  },
  {
    name: 'Asset Number',
    selector: 'asset_number',
    sortable: true,
  },
];

// Show some more info about the sales estimate that we're copying in this modal
const ExpandedCostBreakdown = (row: any) => {
  const { salesEstimate } = React.useContext(EstimateDetailsContext);

  return (
    <div className="expanded-row" style={{ width: '100%', border: '2px solid black', padding: 10 }}>
      <DataTable
        title=""
        columns={assetColumns}
        data={row.data.subtypes}
        striped
        dense
        highlightOnHover
        pointerOnHover
        noHeader
      />
    </div>
  );
};

export default ExpandedCostBreakdown;
