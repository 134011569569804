import React from 'react';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { SystemAccessoriesContext } from '../contexts/SystemAccessoriesContext';
import SystemAccessoryForm from '../components/SystemAccessoryForm';

const SystemAccessoryNew: React.FunctionComponent = () => {
  const { systemAccessoryId } = useParams<Record<string, string>>();
  const history = useHistory();
  const [systemAccessoryFetched, setSystemAccessoryFetched] = React.useState(false);
  const { fetchSystemAccessory, systemAccessory, systemAccessoryLoading } = React.useContext(SystemAccessoriesContext);

  React.useEffect(() => {
    if (!systemAccessoryFetched) {
      setSystemAccessoryFetched(true);
    }
  }, [systemAccessoryFetched, setSystemAccessoryFetched, fetchSystemAccessory, systemAccessoryId]);

  return (
    <>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
        onClick={() => history.push('/admin/system_accessories')}
      >
        ← Return to System Accessories
      </Typography>
      <Typography variant="h5" component="h5">
        New System Accessory Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <SystemAccessoryForm systemAccessory={{}} onSuccess={() => history.push('/admin/system_accessories')} />
        </Grid>
      </Grid>
    </>
  );
};

export default SystemAccessoryNew;
