/* eslint-disable react-hooks/exhaustive-deps, no-alert, no-nested-ternary, react/jsx-props-no-spreading */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AlertBubbleIcon } from '@shopify/polaris-icons';
import { Button, Grid, Stack, Switch, Typography, styled } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import AppsIcon from '@mui/icons-material/Apps';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Navigation } from '@shopify/polaris';

const Sidebar: React.FC = () => {
  const location = useLocation();

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            label: 'Sales Estimates',
            url: '/rooftop_automation/customers/search',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/rooftop_automation/customers/new',
                disabled: false,
                label: 'New Customer',
              },
              {
                url: '/rooftop_automation/customers/search',
                disabled: false,
                label: 'Existing Customer',
              },
              {
                url: '/rooftop_automation/recent',
                disabled: false,
                label: 'Recent Estimates',
              },
            ],
          },
          {
            label: 'Estimate Settings',
            url: '/admin',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/admin/equipment_costs',
                disabled: false,
                label: 'Replacement Equipment',
              },
              {
                url: '/admin/system_accessories',
                disabled: false,
                label: 'System Accessories',
              },
              {
                url: '/admin/installation_costs',
                disabled: false,
                label: 'Installation Costs',
              },
              {
                url: '/admin/rebates',
                disabled: false,
                label: 'Rebates',
              },
            ],
          },
          {
            label: 'ServMan Data',
            url: '/admin',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/admin/servman_customers',
                disabled: false,
                label: 'Customers',
              },
              {
                url: '/admin/servman_assets',
                disabled: true,
                label: 'Assets',
              },
              {
                url: '/admin/servman_equipment_types',
                disabled: false,
                label: 'Equipment Types',
              },
              {
                url: '/admin/servman_client_types',
                disabled: false,
                label: 'Client Types',
              },
              {
                url: '/admin/servman_contact_types',
                disabled: false,
                label: 'Contact Types',
              },
              {
                url: '/admin/servman_phone_types',
                disabled: false,
                label: 'Phone Types',
              },
              {
                url: '/admin/servman_manufacturers',
                disabled: false,
                label: 'Manufacturers',
              },
              {
                url: '/admin/zip_codes',
                disabled: false,
                label: 'Zip Codes',
              },
            ],
          },
        ]}
      />
    </Navigation>
  );
};

export default Sidebar;
