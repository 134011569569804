import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface SalesEstimateLoadingProps {
  progress?: number;
  setProgress?: any;
}

const SalesEstimateLoading: React.FC<SalesEstimateLoadingProps> = (props) => {
  const { progress, setProgress = () => null } = props;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 400);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          {progress ? (
            <LinearProgress variant="buffer" value={progress} valueBuffer={progress + 1} />
          ) : (
            <LinearProgress />
          )}
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {progress ? `${progress}%` : ''}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SalesEstimateLoading;
