import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { RebatesContext } from '../contexts/RebatesContext';
import DataTable from '../../dataTable/DataTable/DataTable';
import RebateFilterBar from '../components/RebateFilterBar';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Rebate Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Utility Provider',
    selector: 'utility_provider_name',
    sortable: true,
  },
  {
    name: 'Technology',
    selector: 'technology',
    sortable: true,
  },
  {
    name: 'L2 Category',
    selector: 'l2_description',
    sortable: true,
  },
  {
    name: 'Min Rebate Rate',
    selector: 'min_rebate_rate',
    sortable: true,
  },
  {
    name: 'Max Rebate Rate',
    selector: 'max_rebate_rate',
    sortable: true,
  },
  {
    name: 'Rebate UOM',
    selector: 'rebate_uom',
    sortable: true,
  },
  {
    name: 'Source',
    selector: 'source',
    sortable: true,
  },
];

const RebatesList: React.FunctionComponent = () => {
  const history = useHistory();

  const [rebateFetched, setRebateFetched] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState({});
  const { fetchRebates, rebates, rebatesLoading } = React.useContext(RebatesContext);

  React.useEffect(() => {
    if (!rebateFetched) {
      fetchRebates();
      setRebateFetched(true);
    }
  }, [rebateFetched, setRebateFetched, fetchRebates]);

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ marginTop: 5 }}>
              Rebates
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push('/admin/rebates/new')}>
              New Rebate +
            </Button>
          </Grid>
        </Grid>
        <br />
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <RebateFilterBar filterValues={filterValues} setFilterValues={setFilterValues} />
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title="Rebates"
              columns={columns}
              data={rebates}
              onRowClicked={(row) => history.push(`/admin/rebates/${row.id}/edit`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default RebatesList;
