import React, { useState, useContext } from 'react';

import { Box, Grid, Typography, Button } from '@mui/material';
import FilterSelectInput from '../../form/components/FilterSelectInput';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';

interface ReplacementEquipmentFilterBarProps {
  filterChoices: any;
}

const ReplacementEquipmentFilterBar: React.FC<ReplacementEquipmentFilterBarProps> = (props) => {
  const { filterChoices } = props;

  const { fetchEquipmentCostOptions } = useContext(EstimateCostsContext);

  const [filterValues, setFilterValues] = useState<any>({});
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);

  const handleApplyFilters = () => {
    fetchEquipmentCostOptions(filterValues);
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={6} md={2}>
        <FilterSelectInput
          value={filterValues['ransack[manufacturer_id_eq]']}
          choices={filterChoices.manufacturer_choices}
          valueChanged={(value) => setFilterValues({ ...filterValues, 'ransack[manufacturer_id_eq]': value })}
          label="Manufacturer"
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <FilterSelectInput
          value={filterValues?.tons}
          choices={filterChoices.tons_choices}
          valueChanged={(value) => setFilterValues({ ...filterValues, tons: value })}
          label="Tons"
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <FilterSelectInput
          value={filterValues?.heating}
          choices={filterChoices.heating_choices}
          valueChanged={(value) => setFilterValues({ ...filterValues, heating: value })}
          label="Heating"
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <FilterSelectInput
          value={filterValues?.orientation}
          choices={filterChoices.orientation_choices}
          valueChanged={(value) => setFilterValues({ ...filterValues, orientation: value })}
          label="Orientation"
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff', marginTop: 19 }}
          disableElevation
          onClick={() => {
            handleApplyFilters();
          }}
        >
          Apply
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => setAdvancedFiltersOpen(!advancedFiltersOpen)}
          color={advancedFiltersOpen ? 'secondary' : 'primary'}
          startIcon={advancedFiltersOpen ? <VisibilityOffIcon /> : <FilterListIcon />}
        >
          {advancedFiltersOpen ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
        </Button>
      </Grid>
      {advancedFiltersOpen && (
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={6} md={2}>
            <FilterSelectInput
              value={filterValues?.manufacturer_id}
              choices={filterChoices.manufacturer_choices}
              valueChanged={(value) => setFilterValues({ ...filterValues, manufacturer_id: value })}
              label="Economizer"
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FilterSelectInput
              value={filterValues?.tons}
              choices={filterChoices.tons_choices}
              valueChanged={(value) => setFilterValues({ ...filterValues, tons: value })}
              label="Voltage"
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FilterSelectInput
              value={filterValues?.heating}
              choices={filterChoices.heating_choices}
              valueChanged={(value) => setFilterValues({ ...filterValues, heating: value })}
              label="Phase"
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FilterSelectInput
              value={filterValues?.orientation}
              choices={filterChoices.orientation_choices}
              valueChanged={(value) => setFilterValues({ ...filterValues, orientation: value })}
              label="SEER"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ReplacementEquipmentFilterBar;
