import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import RebateOptionCard from './RebateOptionCard';

import { Rebate } from '../model';

interface RebateCardCollectionProps {
  rebates: Rebate[];
  title: string;
}

const RebateCardCollection: React.FC<RebateCardCollectionProps> = (props) => {
  let { rebates, title } = props;

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ fontSize: '24px', margin: '-20px 0 20px' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Grid container spacing={2}>
          {rebates.map((rebate) => (
            <Grid item xs={12} md={4}>
              <RebateOptionCard rebate={rebate} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RebateCardCollection;
