import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button } from '@mui/material';
import FilterSelectInput from '../../form/components/FilterSelectInput';
import axios from '../../utils/axios.utils';
import { EstimateDetailsContext } from '../contexts/EstimateDetailsContext';

function RecentEstimatesFilterBar() {
  const [choicesFetched, setChoicesFetched] = useState<boolean>(false);
  const [filterChoices, setFilterChoices] = useState<any>({});

  const { fetchSalesEstimates, filterValues, setFilterValues } = useContext(EstimateDetailsContext);

  const fetchChoices = async () => {
    await axios.get<string, any>('sales_estimates/new').then((response) => {
      setFilterChoices(response?.data);
    });
  };

  useEffect(() => {
    if (!choicesFetched) {
      fetchChoices().then(() => {
        setChoicesFetched(true);
      });
    }
  }, [choicesFetched]);

  const handleApplyFilters = () => {
    fetchSalesEstimates();
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12} md={3}>
        <FilterSelectInput
          value={filterValues?.user_id_eq}
          choices={filterChoices.user_choices}
          valueChanged={(value) => setFilterValues({ ...filterValues, user_id_eq: value })}
          label="User"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff', marginTop: 19 }}
          disableElevation
          onClick={() => {
            handleApplyFilters();
          }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
}

export default RecentEstimatesFilterBar;
