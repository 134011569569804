import React from 'react';
import { Button, Grid } from '@mui/material';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { InstallationCostsContext } from '../contexts/InstallationCostsContext';
import { CostProfile } from '../model';
import { InstallationCost } from '../model';

interface InstallationCostFormProps {
  costProfile?: CostProfile;
  onSuccess?: () => void;
}

const InstallationCostForm: React.FunctionComponent<InstallationCostFormProps> = (props) => {
  const { costProfile, onSuccess } = props;
  const [formValues, setFormValues] = React.useState<InstallationCost>();

  const { createInstallationCost, updateInstallationCost } = React.useContext(InstallationCostsContext);

  React.useEffect(() => {
    setFormValues(costProfile);
  }, [costProfile]);

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleCreateInstallationCost = () => {
    createInstallationCost(formValues, onSuccess);
  };

  const handleUpdateInstallationCost = () => {
    updateInstallationCost(costProfile.id, formValues, onSuccess);
  };

  return (
    <>
      <br />
      <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Name"
              shrinkLabel
              value={formValues?.name}
              valueChanged={(value) => setFormValues({ ...formValues, name: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Cost Per Unit"
              shrinkLabel
              value={formValues?.cost_per_unit}
              valueChanged={(value) => setFormValues({ ...formValues, cost_per_unit: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Cost UOM"
              value={formValues?.cost_uom}
              choices={[
                { label: '$/asset', value: 'asset' },
                { label: '$/kWh', value: 'kWh' },
                { label: '$/job', value: 'job' },
              ]}
              valueChanged={(value) => setFormValues({ ...formValues, cost_uom: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Primary Category"
              shrinkLabel
              value={formValues?.primary_category}
              valueChanged={(value) => setFormValues({ ...formValues, primary_category: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Secondary Category"
              shrinkLabel
              value={formValues?.secondary_category}
              valueChanged={(value) => setFormValues({ ...formValues, secondary_category: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Default Cost"
              shrinkLabel
              value={formValues?.default_cost}
              valueChanged={(value) => setFormValues({ ...formValues, default_cost: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Tooltip"
              shrinkLabel
              value={formValues?.tooltip}
              valueChanged={(value) => setFormValues({ ...formValues, tooltip: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Small Unit Cost"
              shrinkLabel
              value={formValues?.small_unit_cost}
              valueChanged={(value) => setFormValues({ ...formValues, small_unit_cost: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Medium Unit Cost"
              shrinkLabel
              value={formValues?.medium_unit_cost}
              valueChanged={(value) => setFormValues({ ...formValues, medium_unit_cost: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Large Unit Cost"
              shrinkLabel
              value={formValues?.large_unit_cost}
              valueChanged={(value) => setFormValues({ ...formValues, large_unit_cost: value.trim() })}
            />
          </Grid>
        </Grid>
      </form>
      {console.log(formValues)}
      {formValues?.id ? (
        <Button variant="contained" color="primary" onClick={handleUpdateInstallationCost}>
          Update Installation Cost
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={handleCreateInstallationCost}>
          Create Installation Cost
        </Button>
      )}
    </>
  );
};

export default InstallationCostForm;
