import React, { useState, Dispatch, SetStateAction } from 'react';
import axios from '../../utils/axios.utils';
import { SalesEstimateFormValues, SalesEstimate } from '../model';
import { SelectOption } from '../../form/model';

interface EstimateDetailsContextInterface {
  fetchSalesEstimates?: () => void;
  salesEstimates?: SalesEstimate[];
  estimatesLoading?: boolean;

  filterValues?: any;
  setFilterValues?: Dispatch<SetStateAction<any>>;

  fetchSalesEstimate?: (estimate_id: string) => void;
  salesEstimate?: SalesEstimate;
  setSalesEstimate?: Dispatch<SetStateAction<SalesEstimate>>;

  salesEstimateLoading?: boolean;

  updateEstimate?: (formValues: SalesEstimateFormValues, onSuccess: () => void) => void;
  estimateSubmitting?: boolean;

  recentEstimatesDeleteModalOpen?: boolean;
  setRecentEstimatesDeleteModalOpen?: Dispatch<SetStateAction<boolean>>;

  recentEstimatesCopyModalOpen?: boolean;
  setRecentEstimatesCopyModalOpen?: Dispatch<SetStateAction<boolean>>;

  focusEstimate?: SalesEstimate;
  setFocusEstimate?: Dispatch<SetStateAction<SalesEstimate>>;
}

interface EstimateDetailsContextProviderProps {
  children: React.ReactNode;
}

const EstimateDetailsContext = React.createContext<EstimateDetailsContextInterface>({});

const EstimateDetailsContextConsumer = EstimateDetailsContext.Consumer;
const EstimateDetailsContextProvider: React.FC<EstimateDetailsContextProviderProps> = ({ children }) => {
  const [salesEstimates, setSalesEstimates] = useState<SalesEstimate[]>([]);
  const [salesEstimatesFetched, setSalesEstimatesFetched] = useState<boolean>(false);
  const [estimatesLoading, setEstimatesLoading] = useState<boolean>(true);

  const [salesEstimate, setSalesEstimate] = useState<SalesEstimate>({});
  const [salesEstimateLoading, setSalesEstimateLoading] = useState<boolean>(true);

  const [customerChoices, setCustomerChoices] = useState<SelectOption[]>([]);
  const [locationChoices, setLocationChoices] = useState<SelectOption[]>([]);

  const [estimateSubmitting, setEstimateSubmitting] = useState<boolean>(false);

  const [recentEstimatesDeleteModalOpen, setRecentEstimatesDeleteModalOpen] = useState<boolean>(false);
  const [recentEstimatesCopyModalOpen, setRecentEstimatesCopyModalOpen] = useState<boolean>(false);
  const [focusEstimate, setFocusEstimate] = useState<SalesEstimate>({});

  // Currently hard-coded to user_id_eq: 1
  // Set this to the current user after we implement sessions
  const [filterValues, setFilterValues] = useState<any>({ user_id_eq: 1 });

  const fetchSalesEstimates = async () => {
    try {
      const response = await axios.get<string, any>('/sales_estimates', {
        params: { 'ransack[user_id_eq]': filterValues.user_id_eq },
      });
      setSalesEstimates(response?.data?.result);
      setSalesEstimatesFetched(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSalesEstimate = async (estimate_id: string) => {
    setSalesEstimateLoading(true);

    const response: any = await axios.get(`sales_estimates/${estimate_id}`);

    setSalesEstimate(response.data.result);
    setSalesEstimateLoading(false);
  };

  const updateEstimate = async (formValues: SalesEstimateFormValues, onSucess: () => void) => {
    setEstimateSubmitting(true);

    await axios
      .put<SalesEstimateFormValues, any>('/sales_estimates/', { ...formValues })
      .then((response) => {
        onSucess();
      })
      .catch((error) => {
        setEstimateSubmitting(false);
        // setEstimateDetailsError(error?.response?.data?.error);
      });
  };

  return (
    <EstimateDetailsContext.Provider
      value={{
        fetchSalesEstimates,
        salesEstimates,
        estimatesLoading,

        fetchSalesEstimate,
        salesEstimate,
        setSalesEstimate,
        salesEstimateLoading,

        updateEstimate,
        estimateSubmitting,

        filterValues,
        setFilterValues,

        recentEstimatesDeleteModalOpen,
        setRecentEstimatesDeleteModalOpen,
        recentEstimatesCopyModalOpen,
        setRecentEstimatesCopyModalOpen,

        focusEstimate,
        setFocusEstimate,
      }}
    >
      {children}
    </EstimateDetailsContext.Provider>
  );
};

export { EstimateDetailsContextProvider, EstimateDetailsContextConsumer, EstimateDetailsContext };
