/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Card, CardContent, Grid, Typography, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DataTable from '../../dataTable/DataTable/DataTable';
import SalesEstimateStepper from '../components/SalesEstimateStepper';
import { PDFDownloadLink } from '@react-pdf/renderer';
import EstimatePDF from '../../estimateReports/pages/EstimatePDF';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import InformationField from '../../form/components/InformationField';
import EstimateInfoHeader from '../../estimateCustomers/components/EstimateInfoHeader';
import ExpandedAssetCost from '../components/ExpandedAssetCost';
import ExpandedCostBreakdown from '../components/ExpandedCostBreakdown';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import KeyDetailsCard from '../components/KeyDetailsCard';
import {
  rebateDetailsColumns,
  costSummaryColumns,
  reviewAssetColumns,
  filesToDownload,
} from '../constants/salesEstimateReviewColumns';

import axios from '../../utils/axios.utils';
import JobFolder from '../components/JobFolder';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DecodingData = {
  id: 1,
  name: 'NextLink Labs',
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const formatNumber = (number) => (number != null ? parseFloat(number).toFixed(2) : '');

const EstimateDetail: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const { estimateId } = useParams<{ estimateId: string }>();

  const [salesEstimateFetched, setSalesEstimateFetched] = useState<boolean>(false);

  const { salesEstimate, setSalesEstimate, fetchSalesEstimate } = useContext(EstimateDetailsContext);

  const [grossProfitInteger, setGrossProfitInteger] = useState(salesEstimate?.gross_profit_integer);
  const [decoding, setDecoding] = useState<any>({ results: [] });

  useEffect(() => {
    fetchSalesEstimate(estimateId);
    // eslint-disable-next-line
  }, [estimateId]);

  useEffect(() => {
    setGrossProfitInteger(salesEstimate?.gross_profit_integer);
  }, [salesEstimate]);

  const handleSaveGrossProfitInteger = async () => {
    let response: any = await axios.put(`sales_estimates/${estimateId}/gross_profit_integer`, {
      sales_estimate: { gross_profit_integer: grossProfitInteger },
    });

    let result = response?.data?.result;

    setGrossProfitInteger(result.gross_profit_integer);
    setSalesEstimate({
      ...salesEstimate,
      gross_profit_integer: result.gross_profit_integer,
      gross_profit_display: result.gross_profit_display,
      gross_profit_per_man_day: result.gross_profit_per_man_day,
      sales_price: result.sales_price,
    });
  };

  return (
    <Box className={classes.box}>
      <SalesEstimateStepper activeStep={6} salesEstimateId={estimateId} />
      <br />
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <EstimateInfoHeader salesEstimate={salesEstimate} />
          </Grid>
          <Grid item>
            <PDFDownloadLink
              document={<EstimatePDF salesEstimate={salesEstimate} />}
              fileName={`${salesEstimate?.name}.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <Button variant="contained" color="primary" disableElevation>
                  {loading ? 'Loading document...' : 'Proposal'}
                </Button>
              )}
            </PDFDownloadLink>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => history.push(`/rooftop_automation/${estimateId}/spreadsheets`)}
              style={{ marginLeft: 10 }}
            >
              Estimate
            </Button>
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <h3>Estimate Summary</h3>
            <Grid container>
              <Grid item xs={6} md={2}>
                <InformationField label="Sales Price:" value={salesEstimate?.sales_price} />
              </Grid>
              <Grid item xs={6} md={2}>
                <InformationField label="Total Cost:" value={salesEstimate?.total_costs} />
              </Grid>
              <Grid item xs={6} md={2}>
                <InformationField label="Gross Profit:" value={`$${salesEstimate?.gross_profit_display}`} />
              </Grid>
              <Grid item xs={6} md={2}>
                <InformationField label="Total Rebates" value={salesEstimate?.total_rebates} />
              </Grid>
              <Grid item xs={6} md={2}>
                <InformationField label="Contact:" value={'David Donley'} />
              </Grid>
              <Grid item xs={6} md={2}>
                <InformationField label="Phone" value={'724-799-3890'} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <h3>Margin Details</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography gutterBottom>Gross Profit (%)</Typography>
                <TextField
                  type="number"
                  value={grossProfitInteger}
                  fullWidth
                  sx={{
                    '& input': {
                      padding: '8px',
                    },
                  }}
                  onChange={(e) => setGrossProfitInteger(e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 25,
                      max: 36,
                    },
                  }}
                  aria-labelledby="unit-repair-cost-input"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  style={{ marginTop: 31 }}
                  variant="contained"
                  color="primary"
                  onClick={handleSaveGrossProfitInteger}
                >
                  Save GP %
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div">
                  Gross Profit:
                </Typography>
                <Typography>${salesEstimate?.gross_profit_display}</Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div">
                  Total Hours:
                </Typography>
                <Typography>{salesEstimate?.total_hours}</Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div">
                  GPPMD:
                </Typography>
                <Typography>$ {salesEstimate?.gross_profit_per_man_day}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <JobFolder documents={salesEstimate?.documents} />
        <br />
        <KeyDetailsCard
          keyDetails={salesEstimate?.sales_estimate_key_details}
          salesEstimate={salesEstimate}
          setSalesEstimate={setSalesEstimate}
        />

        <br />
        <Card>
          <CardContent>
            <h3>Cost Summary</h3>
            <DataTable
              title=""
              columns={costSummaryColumns}
              data={salesEstimate?.cost_summary}
              striped
              dense
              highlightOnHover
              pointerOnHover
              expandableRows
              expandableRowsComponent={<ExpandedCostBreakdown row />}
              noHeader
            />
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <h3>Asset List</h3>
            <DataTable
              title=""
              columns={reviewAssetColumns}
              data={salesEstimate?.sales_estimate_assets}
              striped
              dense
              highlightOnHover
              pointerOnHover
              expandableRows
              expandableRowsComponent={<ExpandedAssetCost row />}
              noHeader
              pagination
            />
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <h3>Rebate Details</h3>
            <DataTable
              title=""
              columns={rebateDetailsColumns}
              data={salesEstimate?.rebate_details}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <h3>Sales Estimate Change History</h3>
            <DataTable
              title=""
              columns={reviewAssetColumns}
              data={[]}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default EstimateDetail;
