import React, { useState } from 'react';
import axios from '../../utils/axios.utils';
import { Button, Grid, Select, MenuItem } from '@mui/material';
import TextInput from '../../form/components/TextInput';

const techOptions = [
  { value: 'AG', label: 'Agriculture' },
  { value: 'APPL', label: 'Appliance' },
  { value: 'BE', label: 'Building Envelope' },
  { value: 'CA', label: 'Compressed Air' },
  { value: 'CMPTR', label: 'Computer' },
  { value: 'CTRL', label: 'Control' },
  { value: 'EV', label: 'Electric Vehicle' },
  { value: 'FAN', label: 'Fan' },
  { value: 'FNC', label: 'Financing' },
  { value: 'FS', label: 'Food Service' },
  { value: 'HVAC', label: 'HVAC' },
  { value: 'LAND', label: 'Landscaping' },
  { value: 'LED', label: 'LED Light' },
  { value: 'MISC', label: 'Miscellaneous' },
  { value: 'MTR', label: 'Motor' },
  { value: 'NC', label: 'New Construction' },
  { value: 'PL', label: 'Plug Load' },
  { value: 'PRC', label: 'Process' },
  { value: 'PUMP', label: 'Pump' },
  { value: 'RCX', label: 'Rerto-Commissioning' },
  { value: 'RFRG', label: 'Refrigeration' },
  { value: 'RNW', label: 'Renewable' },
  { value: 'SRVC', label: 'Service' },
  { value: 'STRG', label: 'Storage' },
  { value: 'VFD', label: 'Variable Frequency Drive' },
  { value: 'WTR', label: 'Water' },
];

function UtilityGeniusForm() {
  const [ugResponse, setUgResponse] = useState<any>(null);
  const [formValues, setFormValues] = useState<any>({});

  const handleButtonClick = async () => {
    try {
      const response = await axios.get<any>('/utility_genius_endpoints/utilities', { params: formValues });
      setUgResponse(response?.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleProgramClick = async () => {
    try {
      const response = await axios.get<any>('/utility_genius_endpoints/programs', {
        params: { ...formValues },
      });
      setUgResponse(response?.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleIncentivesClick = async () => {
    try {
      const response = await axios.get<any>('/utility_genius_endpoints/incentives', {
        params: { ...formValues },
      });
      setUgResponse(response?.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const NestedObjectDisplay = ({ object }) => (
    <ul>
      {Object.keys(object).map((nestedKey, index) => (
        <li key={index}>
          {nestedKey}:{' '}
          {object[nestedKey] !== null && typeof object[nestedKey] === 'object' ? (
            <NestedObjectDisplay object={object[nestedKey]} />
          ) : object[nestedKey] !== null ? (
            object[nestedKey]
          ) : (
            'N/A'
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div>
      {ugResponse === null ? (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextInput
              label="Zip Code"
              value={formValues?.zip}
              valueChanged={(value: string) => setFormValues({ ...formValues, zip: value })}
            />
          </Grid>
          <Grid item xs={2}>
            <TextInput
              label="State"
              value={formValues?.state}
              valueChanged={(value: string) => setFormValues({ ...formValues, state: value })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Name"
              value={formValues?.name}
              valueChanged={(value: string) => setFormValues({ ...formValues, name: value })}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                handleButtonClick();
              }}
              variant="contained"
            >
              Utilities Info
            </Button>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={2}>
            <TextInput
              label="ID"
              value={formValues?.id}
              valueChanged={(value: string) => setFormValues({ ...formValues, id: value })}
            />
          </Grid>
          <Grid xs={12}></Grid>
          <Grid item xs={7}>
            <Button
              onClick={() => {
                handleProgramClick();
              }}
              variant="contained"
            >
              Programs Info
            </Button>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={2}>
            <TextInput
              label="ID"
              value={formValues?.id}
              valueChanged={(value: string) => setFormValues({ ...formValues, id: value })}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              label="Technology"
              value={formValues?.technology}
              onChange={(e) => setFormValues({ ...formValues, technology: e.target.value })}
              style={{ width: '100%' }}
            >
              {techOptions.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid xs={12}></Grid>
          <Grid item xs={7}>
            <Button
              onClick={() => {
                handleIncentivesClick();
              }}
              variant="contained"
            >
              Incentives Info
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Button onClick={() => setUgResponse(null)} variant="contained">
            Clear
          </Button>
          <ul>
            {Object.keys(ugResponse).map((key, index) => (
              <li key={index}>
                {key}:{' '}
                {typeof ugResponse[key] === 'object' ? (
                  <NestedObjectDisplay object={ugResponse[key]} />
                ) : (
                  ugResponse[key]
                )}
              </li>
            ))}
          </ul>
          <Button onClick={() => setUgResponse(null)} variant="contained">
            Clear
          </Button>
        </>
      )}
    </div>
  );
}

export default UtilityGeniusForm;
