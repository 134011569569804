import React from 'react';
import axios from '../../utils/axios.utils';
import { InstallationCost } from '../model';
import { SelectOption } from '../../form/model';

interface InstallationCostsContextInterface {
  fetchInstallationCosts?: () => void;
  installationCosts?: InstallationCost[];
  installationCostsLoading?: boolean;

  fetchInstallationCost?: (costProfile_id: string) => void;
  installationCost?: InstallationCost;
  installationCostLoading?: boolean;

  fetchChoices?: () => void;
  manufacturerChoices?: SelectOption[];

  createInstallationCost?: (formValues: InstallationCost, onSuccess: () => void) => void;
  updateInstallationCost?: (costProfileId: string, formValues: InstallationCost, onSuccess: () => void) => void;
  costProfileSubmitting?: boolean;
}

interface InstallationCostsContextProviderProps {
  children: React.ReactNode;
}

const InstallationCostsContext = React.createContext<InstallationCostsContextInterface>({});

const InstallationCostsContextConsumer = InstallationCostsContext.Consumer;
const InstallationCostsContextProvider: React.FC<InstallationCostsContextProviderProps> = ({ children }) => {
  const [installationCosts, setCostProfiles] = React.useState<InstallationCost[]>([]);
  const [installationCostsLoading, setCostProfilesLoading] = React.useState<boolean>(true);

  const [installationCost, setInstallationCost] = React.useState<InstallationCost>({});
  const [installationCostLoading, setInstallationCostLoading] = React.useState<boolean>(true);

  const [costProfileSubmitting, setCostProfileSubmitting] = React.useState<boolean>(false);

  const fetchInstallationCosts = async () => {
    const response = await axios.get<string, any>('/installation_costs');

    setCostProfiles(response?.data?.result);
    setCostProfilesLoading(false);
  };

  const fetchInstallationCost = async (costProfileId: string) => {
    const response = await axios.get<string, any>(`/installation_costs/${costProfileId}`);

    setInstallationCost(response?.data?.result);
    setInstallationCostLoading(false);
  };

  const fetchChoices = async () => {
    const response = await axios.get<string, any>('/installation_costs/form_options');

    // setCostProfile(response?.data);
  };

  const createInstallationCost = async (formValues: InstallationCost, onSucess: () => void) => {
    setCostProfileSubmitting(true);

    await axios
      .post<InstallationCost, any>('/installation_costs/', { ...formValues })
      .then((response) => {
        onSucess();
      })
      .catch((error) => {
        setCostProfileSubmitting(false);
      });
  };

  const updateInstallationCost = async (costProfileId: string, formValues: InstallationCost, onSucess: () => void) => {
    setCostProfileSubmitting(true);

    await axios
      .put<InstallationCost, any>(`/installation_costs/${costProfileId}`, { ...formValues })
      .then((response) => {
        onSucess();
      })
      .catch((error) => {
        setCostProfileSubmitting(false);
      });
  };

  return (
    <InstallationCostsContext.Provider
      value={{
        fetchInstallationCosts,
        installationCosts,
        installationCostsLoading,

        fetchInstallationCost,
        installationCost,
        installationCostLoading,

        createInstallationCost,
        updateInstallationCost,
        costProfileSubmitting,
      }}
    >
      {children}
    </InstallationCostsContext.Provider>
  );
};

export { InstallationCostsContextProvider, InstallationCostsContextConsumer, InstallationCostsContext };
