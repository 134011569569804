import React from 'react';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { RebatesContext } from '../contexts/RebatesContext';
import RebateForm from '../components/RebateForm';

const RebateNew: React.FunctionComponent = () => {
  const { rebateId } = useParams<Record<string, string>>();
  const history = useHistory();
  const [rebateFetched, setRebateFetched] = React.useState(false);
  const { fetchRebate, rebate, rebateLoading } = React.useContext(RebatesContext);

  React.useEffect(() => {
    if (!rebateFetched) {
      setRebateFetched(true);
    }
  }, [rebateFetched, setRebateFetched, fetchRebate, rebateId]);

  return (
    <>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
        onClick={() => history.push('/admin/rebates')}
      >
        ← Return to Cost Profiles
      </Typography>
      <Typography variant="h5" component="h5">
        New Rebate Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <RebateForm rebate={{}} onSuccess={() => history.push('/admin/rebates')} />
        </Grid>
      </Grid>
    </>
  );
};

export default RebateNew;
