import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, Grid, Card, Button, Modal } from '@mui/material';
import axios from '../../utils/axios.utils';

function ZipCodesDetail() {
  const { zipCodeZip } = useParams<{ zipCodeZip: string }>();
  const history = useHistory();

  const [zipCodeFetched, setZipCodeFetched] = useState<boolean>(false);
  const [zipCode, setZipCode] = useState<any>({});
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!zipCodeFetched) {
      axios
        .get<any>(`/zip_codes/${zipCodeZip}`)
        .then((response) => {
          setZipCode(response?.data?.result);
          setZipCodeFetched(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [zipCodeZip, zipCodeFetched]);

  const handleDelete = () => {
    axios
      .delete(`/zip_codes/${zipCodeZip}`)
      .then(() => {
        history.push('/admin/zip_codes');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Grid container spacing={2}>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{ padding: 20, textAlign: 'center' }}>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Are you sure you want to delete this zip code?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
            <Button variant="contained" color="error" onClick={handleDelete} style={{ marginRight: 10 }}>
              Delete
            </Button>
            <Button variant="contained" color="secondary" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </Card>
      </Modal>

      <Grid item xs={5}>
        <Typography variant="h4">Zip Codes</Typography>
      </Grid>
      <Grid item xs={4}>
        <Button variant="contained" color="error" onClick={() => setDeleteModalOpen(true)}>
          Delete
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Typography variant="h5">
          {zipCode?.utility_providers?.length > 0 ? 'Utility Providers' : 'No Utility Providers'}
        </Typography>
      </Grid>
      <Grid container>
        {zipCode?.utility_providers?.map((provider: any) => (
          <Grid item xs={5} style={{ margin: 5 }} key={provider.id}>
            <Card>
              <Typography style={{ paddingLeft: 10, padding: 5 }}>{provider.name}</Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default ZipCodesDetail;
