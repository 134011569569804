import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Card, Typography, Button, Grid } from '@mui/material';
import axios from '../../utils/axios.utils';
import { EstimateDetailsContext } from '../contexts/EstimateDetailsContext';
import InformationField from '../../form/components/InformationField';
import TextInput from '../../form/components/TextInput';

// Show some more info about the sales estimate that we're copying in this modal

const RecentEstimatesCopyModal: React.FC = () => {
  const history = useHistory();

  const { recentEstimatesCopyModalOpen, setRecentEstimatesCopyModalOpen, focusEstimate } =
    useContext(EstimateDetailsContext);

  const [newEstimateName, setNewEstimateName] = useState<string>('');

  const handleCopy = async () => {
    await axios
      .post(`/sales_estimates/${focusEstimate.id}/copy`, { sales_estimate: { name: newEstimateName } })
      .then((response: any) => {
        const newRecordId = response.data.id;
        history.push(`/rooftop_automation/${newRecordId}/review`);
      });
  };

  return (
    <Modal
      open={recentEstimatesCopyModalOpen}
      onClose={() => setRecentEstimatesCopyModalOpen(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card style={{ padding: 20, textAlign: 'center' }}>
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          {focusEstimate.name}
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <InformationField label="Customer" value={focusEstimate?.customer_name} />
          </Grid>
          <Grid item xs={6}>
            <InformationField label="Site" value={focusEstimate?.site_name} />
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Typography variant="h4" style={{ marginBottom: 20 }}>
            Copy Sales Estimate
          </Typography>
        </Grid>
        <br />
        <Grid item xs={12}>
          <TextInput
            label="New Estimate Name"
            value={newEstimateName}
            shrinkLabel
            valueChanged={(value: string) => setNewEstimateName(value)}
          />
        </Grid>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
          <Button variant="contained" color="primary" onClick={handleCopy} style={{ marginRight: 10 }}>
            Copy
          </Button>
          <Button variant="contained" color="secondary" onClick={() => setRecentEstimatesCopyModalOpen(false)}>
            Cancel
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default RecentEstimatesCopyModal;
