import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const decodings = [
  {
    manufacturer: 'Asset-Level',
    serial: '$2000',
    description: 'Packaged Unit',
    model: 'Smart Thermostats',
  },
  {
    manufacturer: 'Project-Level',
    model: 'Economizer',
    serial: '$3000',
    description: 'N/A',
  },
];

const costProfiles = [
  {
    manufacturer: 'Packaged Unit',
    model: '0-10 tons',
    serial: '$2000/ton',
  },
  {
    manufacturer: 'Packaged Unit',
    model: '11-20 tons',
    serial: '$1500/ton',
  },
  {
    manufacturer: 'Split System',
    model: '0-10 tons',
    serial: '$3200/ton',
  },
  {
    manufacturer: 'Split System',
    model: '11-20 tons',
    serial: '$2800/ton',
  },
];

const columns: IDataTableColumn<any>[] = [
  {
    name: 'L2 Description',
    selector: 'manufacturer',
    sortable: true,
  },
  {
    name: 'Size Range',
    selector: 'model',
    sortable: true,
  },
  {
    name: 'Default Cost Per Ton',
    selector: 'serial',
    sortable: true,
  },
];

const fixedCostColumns: IDataTableColumn<any>[] = [
  {
    name: 'Category',
    selector: 'manufacturer',
    sortable: true,
  },
  {
    name: 'L2 Asset',
    selector: 'description',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'model',
    sortable: true,
  },
  {
    name: 'Default Cost',
    selector: 'serial',
    sortable: true,
  },
];

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const RecentEstimates: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.box}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h4" component="h4">
              Cost Profiles
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push('/decodings/new')}>
              Submit New Cost Profile +
            </Button>
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title="Decodings"
              columns={columns}
              data={costProfiles}
              dense
              striped
              highlightOnHover
              pointerOnHover
              noHeader
            />
          </CardContent>
        </Card>
        <br />
        <hr />
        <br />
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h4" component="h4">
              Fixed Costs
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push('/decodings/new')}>
              Submit New Fixed Cost +
            </Button>
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title="Decodings"
              columns={fixedCostColumns}
              data={decodings}
              dense
              striped
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default RecentEstimates;
