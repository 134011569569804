import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import techOptions from '../../estimateRebates/constants/techOptions';

function ChooseIncentives() {
  const { utilityId } = useParams<Record<string, string>>();

  const [hvacresponse, setHVACresponse] = useState<any>();
  const [selectedTech, setSelectedTech] = useState<any>({});
  const [otherResponse, setOtherResponse] = useState<any>();

  const getHVACIncentives = async () => {
    try {
      const response = await axios.get<any>('/utility_genius_endpoints/incentives', {
        params: { id: utilityId, technology: 'HVAC' },
      });
      setHVACresponse(response?.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getHVACIncentives();
  }, [utilityId]);

  const handleOtherTechSelect = async (item: any) => {
    setSelectedTech(item);
    try {
      const response = await axios.get<any>('/utility_genius_endpoints/incentives', {
        params: { id: utilityId, technology: item.value },
      });
      setOtherResponse(response?.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  // TODO: Add the title of the Utility Company as a header

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">HVAC Incentives</Typography>
      </Grid>
      {hvacresponse?.map((item: any, index: number) => (
        <Grid item xs={6} key={index}>
          <Card onClick={(ite) => console.log(item.name)}>
            <CardHeader title={item?.category} />
            <CardContent>
              <p>Max Rate: {item.max_rate}</p>
              <p>Min Rate: {item.min_rate}</p>
              <p>Stream: {item.stream}</p>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12}></Grid>

      {selectedTech?.label && (
        <Grid item xs={12}>
          <Typography variant="h4">{selectedTech.label} Incentives</Typography>
        </Grid>
      )}
      <br />
      {otherResponse?.length === 0 ? (
        <p>No incentives found for {selectedTech.label}</p>
      ) : (
        <>
          {otherResponse?.map((item: any, index: number) => (
            <Grid item xs={6} key={index}>
              <Card onClick={(ite) => console.log(item.name)}>
                <CardHeader title={item?.category} />
                <CardContent>
                  <p>Max Rate: {item.max_rate}</p>
                  <p>Min Rate: {item.min_rate}</p>
                  <p>Incentive Type: {item.incentive_type}</p>
                  <p>Stream: {item.stream}</p>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="h4">Other Technologies</Typography>
      </Grid>

      <Grid item xs={12}></Grid>
      {techOptions.map((item, index) => (
        <Grid item xs={4} key={index}>
          <Card onClick={() => handleOtherTechSelect(item)}>
            <CardHeader title={item.label} />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default ChooseIncentives;
