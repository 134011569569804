import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, FilledInput, FormControl, FormHelperText, Input, InputLabel, InputAdornment } from '@mui/material';
import { InputElementEvent } from '../model';

interface TextInputProps {
  value: string;
  label: string;
  onChange?: (element: React.ChangeEvent) => void;
  valueChanged?: (value: string) => void;
  display?: string;
  styleOverrides?: string;
  error?: boolean;
  errorMessage?: string;
  isFilled?: boolean;
  shrinkLabel?: boolean;
  multiline?: boolean;
  type?: string;
  rows?: number;
  inputProps?: Record<string, string | number>;
  currency?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((_theme) => ({
  textField: {
    minHeight: '30px',
    width: '100%',
  },
  helperText: {
    fontSize: '12px',
  },
}));

const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    value,
    label,
    onChange,
    valueChanged,
    styleOverrides,
    errorMessage,
    type,
    display,
    shrinkLabel,
    isFilled = true,
    multiline,
    rows,
    inputProps = {},
    currency = false,
    disabled,
  } = props;

  const classes = useStyles();

  const handleChange = (event: InputElementEvent): void => {
    if (onChange) {
      onChange(event);
    }
    if (valueChanged) {
      valueChanged(event.target.value);
    }
  };

  const formattedInput = (
    <FilledInput
      multiline={multiline}
      rows={rows}
      onChange={handleChange}
      type={type}
      disabled={disabled}
      value={value}
      inputProps={inputProps}
      startAdornment={currency ? <InputAdornment position="start">$</InputAdornment> : undefined}
    />
  );

  return (
    <Box display={display}>
      <FormControl
        variant="filled"
        error={errorMessage?.length > 0}
        className={`${styleOverrides} ${classes.textField}`}
      >
        <InputLabel shrink={shrinkLabel} htmlFor={label}>
          {label}
        </InputLabel>
        {formattedInput}
        {errorMessage && <FormHelperText className={classes.helperText}>{`${label} ${errorMessage}`}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default TextInput;
