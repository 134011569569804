import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InformationField from '../../form/components/InformationField';
import ExistingCustomerForm from '../components/ExistingCustomerForm';
import SalesEstimateStepper from '../../estimateDetails/components/SalesEstimateStepper';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const NewRooftopEstimate: React.FC = () => {
  const classes = useStyles();

  const { estimateId } = useParams<{ estimateId: string }>();

  const [assetsFetched, setAssetsFetched] = React.useState(false);

  const { salesEstimate, fetchSalesEstimate } = React.useContext(EstimateDetailsContext);

  React.useEffect(() => {
    if (!assetsFetched) {
      setAssetsFetched(true);
      fetchSalesEstimate(estimateId);
    }
  }, [estimateId]);

  return (
    salesEstimate?.id && (
      <Box className={classes.box}>
        <SalesEstimateStepper activeStep={0} salesEstimateId={salesEstimate?.id} />
        <Grid container>
          <Grid item xs={12} md={12}>
            <h2 style={{ fontSize: '28px', marginBottom: 10 }}>{salesEstimate?.site_name}</h2>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: 20 }}>
          <Grid item>
            <InformationField label="Customer" value={salesEstimate?.customer_name} />
          </Grid>
          <Grid item>
            <InformationField label="Owned By" value={salesEstimate?.owner_name} />
          </Grid>
          <Grid item>
            <InformationField label="Last Updated" value={salesEstimate?.updated_at} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={10}>
            <ExistingCustomerForm salesEstimate={salesEstimate} />
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default NewRooftopEstimate;
