import React from 'react';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { InstallationCostsContext } from '../contexts/InstallationCostsContext';
import InstallationCostForm from '../components/InstallationCostForm';

const CostProfileNew: React.FunctionComponent = () => {
  const { costProfileId } = useParams<Record<string, string>>();
  const history = useHistory();
  const [costProfileFetched, setCostProfileFetched] = React.useState(false);
  const { fetchInstallationCost, installationCost, installationCostLoading } =
    React.useContext(InstallationCostsContext);

  React.useEffect(() => {
    if (!costProfileFetched) {
      setCostProfileFetched(true);
    }
  }, [costProfileFetched, setCostProfileFetched, fetchInstallationCost, costProfileId]);

  return (
    <>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
        onClick={() => history.push('/admin/equipment_costs')}
      >
        ← Return to Equipment Costs
      </Typography>
      <Typography variant="h5" component="h5">
        New Installation Cost Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <InstallationCostForm costProfile={{}} onSuccess={() => history.push('/admin/installation_costs')} />
        </Grid>
      </Grid>
    </>
  );
};

export default CostProfileNew;
