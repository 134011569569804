import React from 'react';
import { AuthenticationContextProvider } from './modules/auth/contexts/AuthenticationContext';
import { EquipmentCostsContextProvider } from './modules/costSettings/contexts/EquipmentCostsContext';
import { InstallationCostsContextProvider } from './modules/costSettings/contexts/InstallationCostsContext';
import { RebatesContextProvider } from './modules/adminSettings/contexts/RebatesContext';
import { SystemAccessoriesContextProvider } from './modules/adminSettings/contexts/SystemAccessoriesContext';
import { EstimateCostsContextProvider } from './modules/estimateCosts/contexts/EstimateCostsContext';
import { EstimateCustomersContextProvider } from './modules/estimateCustomers/contexts/EstimateCustomersContext';
import { EstimateDetailsContextProvider } from './modules/estimateDetails/contexts/EstimateDetailsContext';
import { EstimateRebatesContextProvider } from './modules/estimateRebates/contexts/EstimateRebatesContext';

interface ContextsProviderProps {
  children: React.ReactNode;
}

const ContextsProvider: React.FC<ContextsProviderProps> = ({ children }) => {
  return (
    <AuthenticationContextProvider>
      <EquipmentCostsContextProvider>
        <InstallationCostsContextProvider>
          <RebatesContextProvider>
            <SystemAccessoriesContextProvider>
              <EstimateCostsContextProvider>
                <EstimateCustomersContextProvider>
                  <EstimateDetailsContextProvider>
                    <EstimateRebatesContextProvider>{children}</EstimateRebatesContextProvider>
                  </EstimateDetailsContextProvider>
                </EstimateCustomersContextProvider>
              </EstimateCostsContextProvider>
            </SystemAccessoriesContextProvider>
          </RebatesContextProvider>
        </InstallationCostsContextProvider>
      </EquipmentCostsContextProvider>
    </AuthenticationContextProvider>
  );
};

export default ContextsProvider;
