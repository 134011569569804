import React, { useState, useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Search from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { SelectOption } from '../../form/model';

import { CurbAdapter } from '../../estimateDetails/model';
import { SalesEstimateAsset } from '../../estimateDetails/model';

import { EstimateCostsContext } from '../contexts/EstimateCostsContext';

import axios from '../../utils/axios.utils';

interface CurbAdapterSubFormProps {
  curbAdapter: CurbAdapter;
  salesEstimateAsset: SalesEstimateAsset;
}

const CurbAdapterSubForm: React.FC<CurbAdapterSubFormProps> = (props) => {
  const { curbAdapter, salesEstimateAsset } = props;

  const { updateCurbAdapter } = useContext(EstimateCostsContext);

  const [formValues, setFormValues] = useState<CurbAdapter>({
    ...curbAdapter,
    micrometl_search_term_existing: '',
    micrometl_search_term_replacement: '',
  });

  const [micrometlOptions, setMicrometlOptions] = useState<{ existing: SelectOption[]; replacement: SelectOption[] }>({
    existing: [],
    replacement: [],
  });

  const fetchMicroMetlInfo = async (whichUnit: 'existing' | 'replacement') => {
    try {
      const query = formValues[`micrometl_search_term_${whichUnit}`];
      const response: any = await axios.get('/sales_estimate_assets/0/curb_adapters/new', { params: { query } });
      setMicrometlOptions((prevOptions) => ({
        ...prevOptions,
        [whichUnit]: response.data.micrometl_options,
      }));
    } catch (error) {
      console.error('Error fetching Micrometl info:', error);
    }
  };

  const fetchCurbAdapterPartName = async () => {
    try {
      let response = await axios.get('/sales_estimate_assets/0/curb_adapters/part_name', { params: formValues });
      let updatedFormValues = { ...formValues, micrometl_part_name: response.data };

      if (response.data === 'No Curb Adapter Required') {
        updatedFormValues.fits_existing = 'true';
      }

      setFormValues(updatedFormValues);
    } catch (error) {
      console.error('Error fetching part name:', error);
    }
  };

  const handleCurbValueChange = (field: string, value: any) => {
    const updatedCurbFormValues = { ...formValues, [field]: value };
    setFormValues(updatedCurbFormValues);
  };

  useEffect(() => {
    if (formValues.selected_micrometl_id_replacement != null && formValues.selected_micrometl_id_existing != null) {
      fetchCurbAdapterPartName();
    }
  }, [formValues.selected_micrometl_id_existing, formValues.selected_micrometl_id_replacement]);

  const handleCurbSubmit = (salesEstimateAssetId) => {
    updateCurbAdapter(salesEstimateAssetId, formValues);
  };

  const renderUnitSection = (unitType: 'existing' | 'replacement', label: string) => (
    <Card style={{ padding: 15 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>{`${label} Unit Model Number: ${salesEstimateAsset[`model_number_${unitType}`]}`}</Typography>
        </Grid>
        <Grid item xs={10} style={{ marginBottom: 15 }}>
          <TextInput
            label={`Micrometl Search Term for ${label} Unit`}
            value={formValues[`micrometl_search_term_${unitType}`]}
            valueChanged={(value) => handleCurbValueChange(`micrometl_search_term_${unitType}`, value)}
          />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" onClick={() => fetchMicroMetlInfo(unitType)} type="button">
            <Search />
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 15 }}>
          <SelectInput
            label={`${label} Unit Micrometl Options (${micrometlOptions[unitType].length})`}
            value={formValues[`selected_micrometl_id_${unitType}`]}
            valueChanged={(value) => handleCurbValueChange(`selected_micrometl_id_${unitType}`, value)}
            choices={micrometlOptions[unitType]}
          />
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <Grid container style={{ backgroundColor: '#dcf5e6', padding: 15, borderRadius: 15 }}>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Typography style={{ fontSize: 22 }}>
          <strong>Asset: </strong> {salesEstimateAsset.asset_number}
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 15 }}>
        {renderUnitSection('existing', 'Existing ')}
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 15 }}>
        {renderUnitSection('replacement', 'Replacement')}
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Card style={{ padding: 15 }}>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!formValues?.fits_existing}
                    onChange={(event) => handleCurbValueChange('fits_existing', event.target.checked)}
                    name="fits_existing"
                    color="primary"
                  />
                }
                label="New Unit Fits Existing Curb"
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <Typography style={{ fontSize: 16 }}>
                <strong>Curb Adapter Part Name: </strong>
                {formValues?.micrometl_part_name}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 15 }}>
              <TextInput
                label="Curb Adapter Cost"
                value={formValues?.cost}
                disabled={!!formValues?.fits_existing}
                valueChanged={(value) => handleCurbValueChange('cost', value)}
                shrinkLabel
                currency
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Submittal URL"
                value={formValues?.submittal_url}
                disabled={!!formValues?.fits_existing}
                valueChanged={(value) => handleCurbValueChange('submittal_url', value)}
                shrinkLabel
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Button variant="contained" onClick={() => handleCurbSubmit(salesEstimateAsset.id)} type="button">
          Save Curb Details
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: 10 }}
          onClick={() =>
            window.open(
              `https://micrometlwebsite.blob.core.windows.net/micrometlwebsiteimages/${formValues?.micrometl_part_name}.pdf`,
              '_blank',
            )
          }
          type="button"
        >
          Preview Document
        </Button>
      </Grid>
    </Grid>
  );
};

export default CurbAdapterSubForm;
