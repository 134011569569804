import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, FilledInput, FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
import { InputElementEvent, SelectOption } from '../model';

interface FilterSelectInputProps {
  value?: string;
  label: string;
  onChange?: (element: React.ChangeEvent) => void;
  valueChanged?: (value: string) => void;
  styleOverrides?: string;
  errorMessage?: string;
  isFilled?: boolean;
  type?: string;
  choices: SelectOption[];
  defaultChoice?: SelectOption;
  textColor?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((_theme) => ({
  textField: {
    height: '30px',
    width: '100%',
    margin: '0 0 4px',
  },
  helperText: {
    fontSize: '12px',
  },
}));

const FilterSelectInput: React.FC<FilterSelectInputProps> = (props) => {
  const {
    label,
    onChange,
    valueChanged,
    styleOverrides,
    errorMessage,
    value = '',
    choices,
    defaultChoice,
    textColor,
    disabled = false,
  } = props;

  const classes = useStyles();

  const handleChange = (event: InputElementEvent): void => {
    if (onChange) {
      onChange(event);
    }
    if (valueChanged) {
      valueChanged(event.target.value);
    }
  };

  const createMenuItem = (choice: SelectOption) =>
    choice ? (
      <MenuItem value={choice.value} key={choice.value}>
        <span>{choice.label}</span>
      </MenuItem>
    ) : null;

  const defaultMenuItem = createMenuItem(defaultChoice);
  const menuItemChoices = (choices || []).map((choice) => createMenuItem(choice));

  return (
    <Box style={{ marginBottom: 25, marginTop: -5 }}>
      <InputLabel htmlFor={label} shrink>
        {label}
      </InputLabel>
      <FormControl
        disabled={disabled}
        variant="filled"
        error={errorMessage?.length > 0}
        className={`${styleOverrides} ${classes.textField} filter-select-input`}
      >
        <Select value={value} input={<FilledInput onChange={handleChange} />}>
          {defaultMenuItem}
          {menuItemChoices}
        </Select>
        {errorMessage && <FormHelperText className={classes.helperText}>{`${label} ${errorMessage}`}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default FilterSelectInput;
