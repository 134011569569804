const techOptions = [
  { value: 'AG', label: 'Agriculture' },
  { value: 'APPL', label: 'Appliance' },
  { value: 'BE', label: 'Building Envelope' },
  { value: 'CA', label: 'Compressed Air' },
  { value: 'CMPTR', label: 'Computer' },
  { value: 'CTRL', label: 'Control' },
  { value: 'EV', label: 'Electric Vehicle' },
  { value: 'FAN', label: 'Fan' },
  { value: 'FNC', label: 'Financing' },
  { value: 'FS', label: 'Food Service' },
  { value: 'HVAC', label: 'HVAC' },
  { value: 'LAND', label: 'Landscaping' },
  { value: 'LED', label: 'LED Light' },
  { value: 'MISC', label: 'Miscellaneous' },
  { value: 'MTR', label: 'Motor' },
  { value: 'NC', label: 'New Construction' },
  { value: 'PL', label: 'Plug Load' },
  { value: 'PRC', label: 'Process' },
  { value: 'PUMP', label: 'Pump' },
  { value: 'RCX', label: 'Retro-Commissioning' },
  { value: 'RFRG', label: 'Refrigeration' },
  { value: 'RNW', label: 'Renewable' },
  { value: 'SRVC', label: 'Service' },
  { value: 'STRG', label: 'Storage' },
  { value: 'VFD', label: 'Variable Frequency Drive' },
  { value: 'WTR', label: 'Water' },
];

export default techOptions;
