import React from 'react';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { RebatesContext } from '../contexts/RebatesContext';
import RebateForm from '../components/RebateForm';

const RebateEdit: React.FunctionComponent = () => {
  const { rebateId } = useParams<Record<string, string>>();
  const history = useHistory();
  const [rebateFetched, setRebateFetched] = React.useState(false);
  const { fetchRebate, rebate, rebateLoading } = React.useContext(RebatesContext);

  React.useEffect(() => {
    if (!rebateFetched) {
      fetchRebate(rebateId);
      setRebateFetched(true);
    }
  }, [rebateFetched]);

  return rebateLoading && rebate?.id ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading Rebate...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
        onClick={() => history.push('/admin/rebates')}
      >
        ← Return to Rebates
      </Typography>
      <Typography variant="h5" component="h5">
        Edit Rebate Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <RebateForm rebate={rebate} onSuccess={() => history.push('/admin/rebates')} />
        </Grid>
      </Grid>
    </>
  );
};

export default RebateEdit;
