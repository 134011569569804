import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import DataTable from '../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../dataTable/DataTable/model';
import axios from '../utils/axios.utils';

// Currently running very slow because it's using client-side pagination

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Code',
    selector: 'code',
    sortable: true,
  },
];

const ServmanEquipmentTypesList: React.FunctionComponent = () => {
  const history = useHistory();

  const [customersFetched, setCustomersFetched] = useState(false);
  const [customers, setCustomers] = useState<any>([]);

  const fetchCustomers = async () => {
    await axios.get('/servman_data/contact_types').then((response: any) => {
      setCustomers(response.data.result);
    });
  };

  useEffect(() => {
    if (!customersFetched) {
      fetchCustomers();
      setCustomersFetched(true);
    }
  }, [customersFetched]);

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ marginTop: 5 }}>
              Contact Types
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title="Rebates"
              columns={columns}
              data={customers}
              onRowClicked={(row) => history.push(`/admin/rebates/${row.id}/edit`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ServmanEquipmentTypesList;
