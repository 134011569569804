import React from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExistingCustomerForm from '../components/ExistingCustomerForm';
import SalesEstimateStepper from '../../estimateDetails/components/SalesEstimateStepper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const NewRooftopEstimate: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <SalesEstimateStepper activeStep={0} />
      <Box>
        <Grid container>
          <Grid item xs={12} md={10}>
            <h2 style={{ fontSize: '36px', marginBottom: 10 }}>New Estimate</h2>
            <br />
            <ExistingCustomerForm salesEstimate={{}} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewRooftopEstimate;
