import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, Grid, Card, Button, Modal } from '@mui/material';
import axios from '../../utils/axios.utils';
import TextInput from '../../form/components/TextInput';

function UtilityProvidersDetail() {
  const { providerId } = useParams<{ providerId: string }>();
  const history = useHistory();

  const [providerFetched, setProviderFetched] = useState<boolean>(false);
  const [provider, setProvider] = useState<any>({});
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [connectZipModalOpen, setConnectZipModalOpen] = useState<boolean>(false);
  const [connectZipModalMessage, setConnectZipModalMessage] = useState<React.ReactNode>(null);
  const [offerAddButton, setOfferAddButton] = useState<boolean>(false);

  const [newZip, setNewZip] = useState<string>('');

  const fetchProvider = async () => {
    axios
      .get<any>(`/utility_providers/${providerId}`)
      .then((response) => {
        setProvider(response?.data?.result);
        setProviderFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (!providerFetched) {
      fetchProvider();
    }
  }, [providerId, providerFetched]);

  const handleDelete = () => {
    axios
      .delete(`/utility_providers/${providerId}`)
      .then(() => {
        history.push('/admin/utility_providers');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ZipAlreadyConnectedMessage = () => {
    return (
      <Typography variant="h5" style={{ marginBottom: 20 }}>
        Zip Code {newZip} is already connected to {provider?.name}.
      </Typography>
    );
  };

  const CreateNewZipModalMessage = () => {
    return (
      <>
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          Zip Code {newZip} is not in our database.
        </Typography>
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          Would you like to add it and connect it to {provider?.name}?
        </Typography>
      </>
    );
  };

  const ConnectExistingZipModalMessage = () => {
    return (
      <Typography variant="h5" style={{ marginBottom: 20 }}>
        Connect {newZip} to {provider?.name}?
      </Typography>
    );
  };

  const handleOpenConnectPrompt = (zip: string) => {
    if (provider?.zip_codes?.find((zipCode: any) => zipCode.zip === zip)) {
      setConnectZipModalMessage(<ZipAlreadyConnectedMessage />);
      setOfferAddButton(false);
    } else {
      axios.get<any>(`/zip_codes/${newZip}/check`).then((response) => {
        if (response?.data?.result) {
          setConnectZipModalMessage(<ConnectExistingZipModalMessage />);
        } else {
          setConnectZipModalMessage(<CreateNewZipModalMessage />);
        }
      });
      setOfferAddButton(true);
    }

    setConnectZipModalOpen(true);
  };

  const handleSubmitConnect = () => {
    axios
      .put(`/utility_providers/${providerId}`, { zip: newZip })
      .then(() => {
        fetchProvider();
        setConnectZipModalOpen(false);
        setNewZip('');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Grid container spacing={2}>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{ padding: 20, textAlign: 'center' }}>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Are you sure you want to delete this provider?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
            <Button variant="contained" color="error" onClick={handleDelete} style={{ marginRight: 10 }}>
              Delete
            </Button>
            <Button variant="contained" color="secondary" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </Card>
      </Modal>
      <Modal
        open={connectZipModalOpen}
        onClose={() => setConnectZipModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{ padding: 20, textAlign: 'center' }}>
          {connectZipModalMessage}
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
            {offerAddButton && (
              <Button variant="contained" color="primary" onClick={handleSubmitConnect} style={{ marginRight: 10 }}>
                Add
              </Button>
            )}
            <Button variant="contained" color="secondary" onClick={() => setConnectZipModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </Card>
      </Modal>
      <Grid item xs={5}>
        <Typography variant="h4">{provider?.name}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Button variant="contained" color="error" onClick={() => setDeleteModalOpen(true)}>
          Delete
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Typography variant="h5">{provider?.zip_codes?.length > 0 ? 'Zip Codes' : 'No Zip Codes'}</Typography>
      </Grid>
      <Grid container>
        {provider?.zip_codes?.map((zipCode: any) => (
          <Grid item xs={2} style={{ margin: 5 }} key={zipCode.id}>
            <Card>
              <Typography style={{ paddingLeft: 10, padding: 5 }}>{zipCode.zip}</Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Connect to Another Zip Code
          </Typography>
          <Grid container>
            <Grid item xs={4}>
              <TextInput type="text" label="Zip Code" value={newZip} valueChanged={(value: any) => setNewZip(value)} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Button variant="contained" color="warning" onClick={() => handleOpenConnectPrompt(newZip)}>
                Connect
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default UtilityProvidersDetail;
