import Button from '@mui/material/Button';
import { IDataTableColumn } from 'react-data-table-component';

export const rebateDetailsColumns: IDataTableColumn<any>[] = [
  {
    name: 'Rebate Name',
    selector: 'name',
    sortable: true,
    // cell: (row) => <p>First Energy HVAC</p>,
  },
  {
    name: 'Rate',
    selector: 'rate',
    sortable: true,
    // cell: (row) => <p>$0.70</p>,
  },
  {
    name: 'Energy Savings',
    selector: 'energy_savings',
    sortable: true,
    // cell: (row) => <p>2,000</p>,
  },
  {
    name: 'Estimated Amount',
    selector: 'estimated_amount',
    sortable: true,
    // cell: (row) => <p>$1,400</p>,
  },
  {
    name: 'Actions',
    selector: 'code',
    sortable: true,
    grow: 1.5,
    cell: (row) => (
      <Button variant="contained" color="primary" onClick={() => null}>
        Submit For Review
      </Button>
    ),
  },
];

const changeLogColumns: IDataTableColumn<any>[] = [
  {
    name: 'Field',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.asset_number}</p>,
  },
  {
    name: 'Previous Value',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number}</p>,
  },
  {
    name: 'Updated Value',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number_replacement}</p>,
  },
  {
    name: 'Performed By',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.size}</p>,
  },
  {
    name: 'Timestamp',
    selector: 'vintage',
    sortable: true,
    cell: (row) => <p>{row.vintage}</p>,
  },
];

const keyDetailColumns: IDataTableColumn<any>[] = [
  {
    name: 'Folder Clean',
    selector: 'name',
    sortable: false,
    cell: (row) => <p>Yes</p>,
  },
  {
    name: 'Tax Exmpt',
    selector: 'name',
    sortable: false,
    cell: (row) => <p>No</p>,
  },
  {
    name: 'Night Work',
    selector: 'name',
    sortable: false,
    cell: (row) => <p>No</p>,
  },
  {
    name: 'Wknd Work',
    selector: 'name',
    sortable: false,
    cell: (row) => <p>No</p>,
  },
  {
    name: 'POC',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>david</p>,
  },
  {
    name: 'Urgency',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>ASAP</p>,
  },
  {
    name: 'Permit REQ',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>No</p>,
  },
  {
    name: 'Turnover Mtg',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>Phone</p>,
  },
  {
    name: 'Closeout Type',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>None</p>,
  },
  {
    name: 'Startup Type',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>Projects</p>,
  },
  {
    name: 'Lead Tech',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>ANY</p>,
  },
];

export const costSummaryColumns: IDataTableColumn<any>[] = [
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    // TODO: Add style bold for category header
    cell: (row) => <p style={{ textTransform: 'capitalize', fontWeight: 700 }}>{row.category}</p>,
  },
  {
    name: 'Total Cost',
    selector: 'total_cost',
    sortable: true,
  },
  {
    name: 'GP%',
    selector: 'name',
    sortable: true,
    // TODO: Change this to track per category
    cell: (row) => <p>35%</p>,
  },
  {
    name: 'Sell Price',
    selector: 'sell_price',
    sortable: true,
  },
];

export const reviewAssetColumns: IDataTableColumn<any>[] = [
  {
    name: 'Asset Name',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.asset_number}</p>,
  },
  {
    name: 'Existing Model Number',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number}</p>,
  },
  {
    name: 'Replacement Model Number',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number_replacement}</p>,
  },
  {
    name: 'Size',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.size}</p>,
  },
  {
    name: 'Age',
    selector: 'age',
    sortable: true,
    cell: (row) => <p>{row.age}</p>,
  },
];

export const filesToDownload = [
  {
    url: 'https://tudi-admin-job-folders-production.s3.us-east-2.amazonaws.com/aa44a016-b1ff-4f76-85c2-434504c6c726/Chaska%20Building%20220%20-%20Replace%20RTU-08%203Ton%20.xlsx?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjED0aCXVzLWVhc3QtMiJHMEUCIQCxx%2BXVUNbNQ%2B%2Beo2TBdLJRDgkaSnkIDqRK%2FdJbZHz7hgIgOUSTqHmV0mAdT%2FoLTA45Vz7oUtWCZLkF5xtC92kXMRsq7AMIpv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw1MzE5MzEyNDM4OTkiDFOrvr%2BG4XXQBmIsXSrAA4oQeOifMu0%2BqVrAE6JFIM%2Bj6NyVOy8epUP36Ft8NBPjd3Jhljuhh9U358cIMBU1Whokxq06rGEazXnBYykjsWfhT9cIvZKBKX7Ts6MQI7HOZ%2B0whXe6XtLhByCmwBesJGClHL4ZP4228GXpAFh%2B1PiP6mO4QP7qhySr4UcZkrd%2BZmbEoVWNUQhtRJeMkckUEdKFw6iPfHKey8lywZF8JbBArJfY2490dTKh1%2F%2F6w9u%2B3M8MI0tJZmXy5IrKIyS0jE88zrOyA1GQ2bZvDTpRmSbVSxfUkam1wi58FMHmXl2twhaHEJYTIWXP7obOpil5xAHUtXvYLeUelpMnOEJZ8xmt7g49b7Km4DpMidDMgFACnL3oMAtC8xwDQlBzjj8E4ubTqCnJnEH6v028t%2BCpNHIkHCvif2wJT5%2Bwv7h1zT%2Ft34Qth4sGmrD3JDQ2qkYuQUYtNvwMsLheanf5VpFse1JLUIr62cwFkAGYm4o0lxuKRRFrY6LNt3umsR6znDBFq5pD3TQ%2BqatdWuh713ye1dpsG3ZVsF2b1qqPQaAFViTiTMucXh8kUPeaRB%2BGpT%2FYGzpZHJw1z8piAHsAcwIJv%2BgwqOCSsgY6lAI86lVKW1gtDhb0oovsaBP%2BXi510MmRUj9gVb11zoncF%2B0MIEfkvbMYJh2xpg0ZZTw6yMRPOLW%2FGkjRfda69WVcMjgoFpvk9bOaHhAlXf80c0Xl7BZKIys%2BGY%2FJbsPfn%2FteJKfUKbdETK4URFdfyJUM47XW0rTzgjSv9n6iC66JVM%2FMWcgve2soaFV4YlwS2JlvJra463AUi7zv%2BJCnxnOtim1e%2BlMrIXGNo4JQe9g2Z2XbSiRLPesLduvc5gUe6ArWdLr6HW91Q14V4wAhqr8wLqyd5L9Qx1XqGi%2FrF7AwMNbNA6fulgwFb6juHAVQ7Z1KTELtmy%2FV1QIs8fUZUGydI74%2B46d3mcKPmtYwTbwY4l9WVx4%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240515T133243Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAXXWMSUV5VKVO4MWF%2F20240515%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=a81b103e125f7b6c4d1e9115d0a418595019349ae152e36ee69b01a840b05263',
    filename: 'Chaska Building 220 - Replace RTU-08.xlsx',
  },
  {
    url: 'https://tudi-admin-job-folders-production.s3.us-east-2.amazonaws.com/aa44a016-b1ff-4f76-85c2-434504c6c726/Tudi%20Chaska%20Tudi%20Chaska%20YSC036%20%2003-19-2024%2003-19-2024.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjED0aCXVzLWVhc3QtMiJHMEUCIQCxx%2BXVUNbNQ%2B%2Beo2TBdLJRDgkaSnkIDqRK%2FdJbZHz7hgIgOUSTqHmV0mAdT%2FoLTA45Vz7oUtWCZLkF5xtC92kXMRsq7AMIpv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw1MzE5MzEyNDM4OTkiDFOrvr%2BG4XXQBmIsXSrAA4oQeOifMu0%2BqVrAE6JFIM%2Bj6NyVOy8epUP36Ft8NBPjd3Jhljuhh9U358cIMBU1Whokxq06rGEazXnBYykjsWfhT9cIvZKBKX7Ts6MQI7HOZ%2B0whXe6XtLhByCmwBesJGClHL4ZP4228GXpAFh%2B1PiP6mO4QP7qhySr4UcZkrd%2BZmbEoVWNUQhtRJeMkckUEdKFw6iPfHKey8lywZF8JbBArJfY2490dTKh1%2F%2F6w9u%2B3M8MI0tJZmXy5IrKIyS0jE88zrOyA1GQ2bZvDTpRmSbVSxfUkam1wi58FMHmXl2twhaHEJYTIWXP7obOpil5xAHUtXvYLeUelpMnOEJZ8xmt7g49b7Km4DpMidDMgFACnL3oMAtC8xwDQlBzjj8E4ubTqCnJnEH6v028t%2BCpNHIkHCvif2wJT5%2Bwv7h1zT%2Ft34Qth4sGmrD3JDQ2qkYuQUYtNvwMsLheanf5VpFse1JLUIr62cwFkAGYm4o0lxuKRRFrY6LNt3umsR6znDBFq5pD3TQ%2BqatdWuh713ye1dpsG3ZVsF2b1qqPQaAFViTiTMucXh8kUPeaRB%2BGpT%2FYGzpZHJw1z8piAHsAcwIJv%2BgwqOCSsgY6lAI86lVKW1gtDhb0oovsaBP%2BXi510MmRUj9gVb11zoncF%2B0MIEfkvbMYJh2xpg0ZZTw6yMRPOLW%2FGkjRfda69WVcMjgoFpvk9bOaHhAlXf80c0Xl7BZKIys%2BGY%2FJbsPfn%2FteJKfUKbdETK4URFdfyJUM47XW0rTzgjSv9n6iC66JVM%2FMWcgve2soaFV4YlwS2JlvJra463AUi7zv%2BJCnxnOtim1e%2BlMrIXGNo4JQe9g2Z2XbSiRLPesLduvc5gUe6ArWdLr6HW91Q14V4wAhqr8wLqyd5L9Qx1XqGi%2FrF7AwMNbNA6fulgwFb6juHAVQ7Z1KTELtmy%2FV1QIs8fUZUGydI74%2B46d3mcKPmtYwTbwY4l9WVx4%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240515T133515Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAXXWMSUV5VKVO4MWF%2F20240515%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=a78e5907f68eca4b898f8b4861fab859e5f2467a98149c0f633c711be55aad69',
    filename: 'Tudi Chaska Tudi Chaska YSC036  03-19-2024 03-19-2024.pdf',
  },
  {
    url: 'https://www.trane.com/content/dam/Trane/Commercial/global/products-systems/equipment/unitary/rooftop-systems/precedent-3-to-10-tons/RT-PRC039E-EN_03302020.pdf',
    filename: 'RTU-08 Replacement Manual.pdf',
  },
  {
    url: 'https://tudi-admin-job-folders-production.s3.us-east-2.amazonaws.com/aa44a016-b1ff-4f76-85c2-434504c6c726/YCD180B4%20DisplayAllDrawingsPDF.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjED0aCXVzLWVhc3QtMiJHMEUCIQCxx%2BXVUNbNQ%2B%2Beo2TBdLJRDgkaSnkIDqRK%2FdJbZHz7hgIgOUSTqHmV0mAdT%2FoLTA45Vz7oUtWCZLkF5xtC92kXMRsq7AMIpv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw1MzE5MzEyNDM4OTkiDFOrvr%2BG4XXQBmIsXSrAA4oQeOifMu0%2BqVrAE6JFIM%2Bj6NyVOy8epUP36Ft8NBPjd3Jhljuhh9U358cIMBU1Whokxq06rGEazXnBYykjsWfhT9cIvZKBKX7Ts6MQI7HOZ%2B0whXe6XtLhByCmwBesJGClHL4ZP4228GXpAFh%2B1PiP6mO4QP7qhySr4UcZkrd%2BZmbEoVWNUQhtRJeMkckUEdKFw6iPfHKey8lywZF8JbBArJfY2490dTKh1%2F%2F6w9u%2B3M8MI0tJZmXy5IrKIyS0jE88zrOyA1GQ2bZvDTpRmSbVSxfUkam1wi58FMHmXl2twhaHEJYTIWXP7obOpil5xAHUtXvYLeUelpMnOEJZ8xmt7g49b7Km4DpMidDMgFACnL3oMAtC8xwDQlBzjj8E4ubTqCnJnEH6v028t%2BCpNHIkHCvif2wJT5%2Bwv7h1zT%2Ft34Qth4sGmrD3JDQ2qkYuQUYtNvwMsLheanf5VpFse1JLUIr62cwFkAGYm4o0lxuKRRFrY6LNt3umsR6znDBFq5pD3TQ%2BqatdWuh713ye1dpsG3ZVsF2b1qqPQaAFViTiTMucXh8kUPeaRB%2BGpT%2FYGzpZHJw1z8piAHsAcwIJv%2BgwqOCSsgY6lAI86lVKW1gtDhb0oovsaBP%2BXi510MmRUj9gVb11zoncF%2B0MIEfkvbMYJh2xpg0ZZTw6yMRPOLW%2FGkjRfda69WVcMjgoFpvk9bOaHhAlXf80c0Xl7BZKIys%2BGY%2FJbsPfn%2FteJKfUKbdETK4URFdfyJUM47XW0rTzgjSv9n6iC66JVM%2FMWcgve2soaFV4YlwS2JlvJra463AUi7zv%2BJCnxnOtim1e%2BlMrIXGNo4JQe9g2Z2XbSiRLPesLduvc5gUe6ArWdLr6HW91Q14V4wAhqr8wLqyd5L9Qx1XqGi%2FrF7AwMNbNA6fulgwFb6juHAVQ7Z1KTELtmy%2FV1QIs8fUZUGydI74%2B46d3mcKPmtYwTbwY4l9WVx4%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240515T134410Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAXXWMSUV5VKVO4MWF%2F20240515%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=109857020cdfcbc282c46b5453e8e8762f586363f902552f257055cf24d42a2b',
    filename: 'Curb Adapter.pdf',
  },
];
