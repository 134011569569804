import React, { useContext, useState } from 'react';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

const assetColumns: IDataTableColumn<any>[] = [
  {
    name: 'Asset Name',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.asset_number}</p>,
  },
  {
    name: 'Existing Model Number',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number}</p>,
  },
  {
    name: 'Replacement Model Number',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number_replacement}</p>,
  },
  {
    name: 'Size',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.size}</p>,
  },
  {
    name: 'Age',
    selector: 'age',
    sortable: true,
    cell: (row) => <p>{row.age}</p>,
  },
];

// Show some more info about the sales estimate that we're copying in this modal
const ExpandedAssetCost = (row: any) => {
  const { salesEstimate } = React.useContext(EstimateDetailsContext);

  return (
    <div className="expanded-row" style={{ width: '100%', border: '2px solid black', padding: 10 }}>
      <h4 style={{ padding: '10px 0' }}>System Accessories</h4>
      <DataTable
        title=""
        columns={assetColumns}
        data={salesEstimate?.sales_estimate_assets}
        striped
        dense
        highlightOnHover
        pointerOnHover
        noHeader
      />
      <br />
      <hr />
      <h4 style={{ padding: '10px 0' }}>Curb Adapter</h4>
      <DataTable
        title=""
        columns={assetColumns}
        data={salesEstimate?.sales_estimate_assets}
        striped
        dense
        highlightOnHover
        pointerOnHover
        noHeader
      />
      <br />
      <hr />
      <h4 style={{ padding: '10px 0' }}>Installation Costs</h4>
      <DataTable
        title=""
        columns={assetColumns}
        data={salesEstimate?.sales_estimate_assets}
        striped
        dense
        highlightOnHover
        pointerOnHover
        noHeader
      />
    </div>
  );
};

export default ExpandedAssetCost;
